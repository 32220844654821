@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.StatusLegend {
	width: min-content;
	height: 100%;
	padding: 0.8rem 1rem;
	@include flex_row(flex-start, flex-start);
	margin-left: auto;

	&_wrapper {
		&_item {
			@include flex_row(flex-start, center);
			margin-bottom: 0.3rem;

			&_badge {
				width: 1.5rem;
				height: 1.5rem;
				border-radius: 50%;
				margin-right: 0.5rem;
				box-shadow: map-get($map: $shadows, $key: "light");
			}
			&_desc {
				font-size: 1.2rem;
				font-weight: 500;
			}
		}
		&_desc {
			margin-top: 0.5rem;
			width: 14rem;
			height: 100%;
			overflow-wrap: break-word;

			&_text {
				font-size: 1.1rem;
			}
		}
	}
}

.LegendBadge {
	@include flex_row(flex-start, center);
	margin-bottom: 0.3rem;

	&_badge {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		margin-right: 0.5rem;
		box-shadow: map-get($map: $shadows, $key: "light");
	}
	&_desc {
		font-size: 1.2rem;
		font-weight: 500;
		width: 8rem;
	}
	// &_desc {
	// 	margin-top: 0.5rem;
	// 	width: 14rem;
	// 	height: 100%;
	// 	overflow-wrap: break-word;

	// }
}
