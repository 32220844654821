@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PastDueController {
	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	padding: 1rem;
	background-color: #f8f8ff;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin-bottom: 2rem;
	@include flex_col(flex-start, flex-start);

	&_title {
		font-size: 1.5rem;
		color: map-get($map: $colors, $key: "charcoal");
		text-decoration: underline;
		margin-bottom: 3rem;
	}

	&_row {
		width: 100%;
		height: auto;
		margin-bottom: 1.5rem;
	}

	&_controls {
		width: 100%;
		height: max-content;
		@include flex_row(flex-end, flex-end);
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;

		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $colors, $key: "mainRed");
		}
	}
}
