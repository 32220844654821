@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetPasswordByQuestions {
	@include fill_container;
	padding: 0 2rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 1rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_confirm {
		width: 100%;
		padding: 2rem 1rem;
		margin-bottom: 2rem;
	}

	&_loader {
		width: 100%;
		@include flex_col(center, center);

		&_text {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_allQuestions {
		width: 100%;

		&_question {
			width: 100%;
			height: auto;
			padding: 1rem 1rem;
			margin-bottom: 4rem;

			&_label {
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				background-color: map-get($map: $blueMap, $key: "600");
				color: #ffffff;
				@include flex_row(center, center);
				font-size: 1.7rem;
				font-weight: 600;
				font-family: map-get($map: $fonts, $key: "altSans");
				margin-bottom: 1rem;
			}
		}
	}
	&_submit {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
		@include flex_row(flex-end, center);
	}
}

.QuestionNumber {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	background-color: map-get($map: $blueMap, $key: "600");
	color: #ffffff;
	@include flex_row(center, center);
	font-size: 1.7rem;
	font-weight: 600;
	font-family: map-get($map: $fonts, $key: "altSans");
	margin-bottom: 1rem;
}
