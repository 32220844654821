@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DueDatePicker {
	width: 100%;
	height: auto;
	// @include flex_row(flex-start, center);

	&_current {
		font-size: 1.6rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
		// color:
		margin-bottom: 4rem;
	}

	&_update {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
		&_date {
			width: auto;
			margin-right: 1rem;
		}
		&_time {
			width: auto;
		}
	}
}

.CurrentDateTime {
	width: 100%;
	height: auto;
	@include flex_row(flex-start, center);
	font-size: 1.6rem;
	font-weight: 600;
	font-family: map-get($map: $fonts, $key: "altSans");

	&_separator {
		color: map-get($map: $blueGreyMap, $key: "400");
		margin: 0 0.5rem;
	}

	&_date {
		color: map-get($map: $blueGreyMap, $key: "700");

		& > b {
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
	&_time {
		color: map-get($map: $redMap, $key: "600");
	}
}
