@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

// mini menu (from 3 dots icon)
.RecentlyViewedReportCardMenu {
	display: block;
	position: absolute;
	top: 2rem;
	right: 2rem;
	width: 20rem;
	height: 15rem;
	padding: 1rem 0.5rem;
	border-radius: 0.5rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "heavySubtle");
	z-index: 5;

	&_list {
		list-style: none;

		&_item {
			width: 100%;
			padding: 0.4rem 0.7rem;
			font-size: 1.2rem;
			border-radius: 0.5rem;
			font-weight: 600;
			@include flex_row(flex-start, center);
			margin-bottom: 0.5rem;

			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
				background-color: map-get($map: $greys, $key: "chalk");
				color: map-get($map: $colors, $key: "main");
			}
			&_icon {
				margin-right: 0.5rem;
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");

				&_close {
					@extend .RecentlyViewedReportCardMenu_list_item_icon;
					fill: map-get($map: $colors, $key: "mainRed");
				}
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "main");
				}
			}
		}
	}
}

// SMALL SCREENS
@media only screen and (max-width: 550px) {
	.RecentlyViewedReportCard {
		width: 25rem;
		height: 20rem;
		padding: 1rem 0.5rem;
		border-radius: 0.5rem;
		background-color: #fff;
		border: 1px solid #ccc;
		margin: 0.5rem 1rem;
		@include flex_col(flex-start, flex-start);
		transition: all 0.3s ease-in-out;
		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "medSubtle");
			border: 1px solid map-get($map: $colors, $key: "main");
		}
		&:hover &_top_name {
			color: map-get($map: $colors, $key: "main");
		}

		&_top {
			position: relative;
			width: 100%;
			margin-bottom: 0.5rem;
			@include flex_row(space-between, flex-start);

			&_name {
				font-size: 1.3rem;
				font-weight: 800;
				color: map-get($map: $colors, $key: "mainBlackBlue");
				transition: all 0.3s ease-in-out;
			}

			&_icon {
				width: 1.5rem;
				height: 1.5rem;
				fill: map-get($map: $blueGreys, $key: "subheadings");
				align-self: flex-start;
				margin-right: 0.5rem;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
		}

		&_desc {
			width: 100%;
			height: auto;
			margin-bottom: 0.5rem;

			&_text {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-bottom: 0.4rem;
			}
			&_dateCreated {
				color: map-get($map: $blueGreys, $key: "headings");

				& > b {
					font-family: map-get($map: $fonts, $key: "altSans");
					color: map-get($map: $colors, $key: "mainBlackBlue");
				}
			}
		}
		&_actions {
			width: 100%;
			max-height: 100%;
			min-height: 40%;
			height: auto;
			@include flex_row(space-between, flex-end);
			flex-basis: 30%;
			flex-shrink: 1;
			flex-grow: 2;

			&_rerunReport {
				border: none;
				outline: none;
				@include flex_row(flex-start, center);
				color: map-get($map: $colors, $key: "main");
				background-color: transparent;
				cursor: pointer;

				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainBlue");
				}
				&:hover &_icon {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainBlue");
				}

				&_icon {
					width: 1.7rem;
					height: 1.7rem;
					fill: map-get($map: $colors, $key: "main");
				}
			}
			&_remove {
				border: none;
				outline: none;
				@include flex_row(flex-start, center);
				color: map-get($map: $colors, $key: "charcoal");
				cursor: pointer;
				background-color: transparent;
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainRed");
				}
				&:hover &_icon {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainRed");
				}

				&_icon {
					width: 1.7rem;
					height: 1.7rem;
					fill: map-get($map: $colors, $key: "mainRed");
				}
			}
		}
	}
}

// TABLETS AND LARGER SCREENS
@media only screen and (min-width: 551px) {
	.RecentlyViewedReportCard {
		width: 35rem;
		height: 20rem;
		padding: 1rem 0.5rem;
		border-radius: 0.5rem;
		background-color: #fff;
		border: 1px solid #ccc;
		margin: 0.5rem 1rem;
		@include flex_col(flex-start, flex-start);
		transition: all 0.3s ease-in-out;

		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "medSubtle");
			border: 1px solid map-get($map: $colors, $key: "main");
		}
		&:hover &_top_name {
			color: map-get($map: $colors, $key: "main");
		}

		&_top {
			position: relative;
			width: 100%;
			padding: 0 1rem;
			margin-bottom: 0.5rem;
			@include flex_row(space-between, flex-start);

			&_name {
				font-size: 1.6rem;
				font-weight: 800;
				color: map-get($map: $colors, $key: "mainBlackBlue");
				transition: all 0.3s ease-in-out;
			}

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $blueGreys, $key: "subheadings");
				align-self: flex-start;
				margin-right: 0.5rem;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
		}

		&_desc {
			width: 100%;
			height: auto;
			padding: 0 1rem;
			margin-bottom: 0.5rem;

			&_text {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-bottom: 1rem;
			}
			&_dateCreated {
				font-size: 1.2rem;
				color: map-get($map: $blueGreys, $key: "headings");

				& > b {
					font-family: map-get($map: $fonts, $key: "altSans");
					color: map-get($map: $colors, $key: "mainBlackBlue");
				}
			}
		}
		&_actions {
			width: 100%;
			max-height: 100%;
			min-height: 40%;
			height: auto;
			@include flex_row(space-between, flex-end);
			flex-basis: 30%;
			flex-shrink: 1;
			flex-grow: 2;

			&_rerunReport {
				border: none;
				outline: none;
				@include flex_row(flex-start, center);
				color: map-get($map: $colors, $key: "main");
				background-color: transparent;
				cursor: pointer;

				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainBlue");
				}
				&:hover &_icon {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainBlue");
				}

				&_icon {
					width: 1.7rem;
					height: 1.7rem;
					fill: map-get($map: $colors, $key: "main");
				}
			}
			&_remove {
				border: none;
				outline: none;
				@include flex_row(flex-start, center);
				color: map-get($map: $colors, $key: "charcoal");
				cursor: pointer;
				background-color: transparent;
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainRed");
				}
				&:hover &_icon {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainRed");
				}

				&_icon {
					width: 1.7rem;
					height: 1.7rem;
					fill: map-get($map: $colors, $key: "mainRed");
				}
			}
		}
	}
}
