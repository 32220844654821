@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";
@import "../../sass/animations";

.FloatingButton {
	position: fixed;
	bottom: 2rem;
	left: 2rem;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	@include flex_row(center, center);
	box-shadow: map-get($map: $shadows, $key: "purpleRain");
	z-index: 1000;

	&_btn {
		@include flex_row(center, center);
		border-radius: 50%;
		outline: none;
		border: none;
		background-color: map-get($map: $colors, $key: "main");
		color: #ffffff;
		font-size: 1.6rem;
		@include fill_container;
		transition: all 0.1s ease-in-out;

		&:hover {
			cursor: pointer;
			background-color: map-get($map: $colors, $key: "mainBlue");
			color: #ffffff;
		}
	}
}

@media screen and (max-width: 450px) {
	.FloatingButton {
		position: fixed;
		bottom: 1rem;
		left: 1rem;
		width: 3.2rem;
		height: 3.2rem;
	}
}
