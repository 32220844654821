@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportSortPicker {
	width: 100%;

	&_removeAll {
		width: 100%;
		margin-top: 1rem;
		@include flex_row(flex-end, flex-end);

		&_btn {
			@include flex_row(center, center);
			padding: 0.6rem 1rem;
			border-radius: 5rem;
			font-weight: 600;
			font-size: 1.4rem;
			background-color: transparent;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			border: 1px solid map-get($map: $blueGreys, $key: "subheadings");
			outline: none;
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $colors, $key: "mainRed");
				color: #ffffff;
			}
			&:hover &_icon {
				fill: #ffffff;
			}
			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainRed");
				margin-right: 0.3rem;
			}
		}
	}
}
