@import "../../sass/mixins";
@import "../../sass/variables";

.Placeholder {
	width: max-content;
	height: max-content;
	text-align: center;

	&_msg {
		font-family: map-get($map: $fonts, $key: "mainSans");
	}
}
