@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RepeatTaskWindow {
	max-width: 100vw;
	max-height: 100%;
	width: 40rem;
	height: 37rem;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -17.5rem;
	margin-left: -20rem;
	background-color: #ffffff;
	color: map-get($map: $colors, $key: "mainBlackBlue");
	border-radius: 0.5rem;
	box-shadow: map-get($map: $shadows, $key: "pronounced");
	z-index: 3;

	&_top {
		width: 100%;
		height: 3rem;
		padding: 2rem 1rem;
		@include flex_row(space-between, center);
		margin-bottom: 3rem;

		&_heading {
			font-size: 2rem;
			font-weight: 700;
			margin-top: 2rem;
			margin-left: 1rem;
		}
		&_closeIcon {
			margin-top: 2rem;
			margin-right: 1rem;
			padding: 0.3rem;
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $colors, $key: "mainRed");
			border-radius: 0.5rem;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
				-webkit-transform: rotateZ(360deg);
				transform: rotateZ(360deg);
				background-color: map-get($map: $greys, $key: "chalk");
			}
		}
	}
	// START DATE W/ DATE PICKER
	&_repeatType {
		width: 100%;
		height: auto;
		padding: 1rem 4rem;
		@include flex_row(flex-start, center);
		margin-bottom: 2rem;

		&_occurs {
			font-size: 1.4rem;
			font-weight: 600;
			margin-right: 1rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_date {
			font-size: 1.4rem;
			font-weight: 800;
			margin-right: 1rem;
			text-decoration: underline;
		}
		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $colors, $key: "mainBlackBlue");
			margin-top: 0.2rem;
			&:hover {
				cursor: pointer;
				fill: #ffffff;
			}
		}
	}
	// REPEAT CYCLE OPTIONS
	&_repeatCycle {
		width: 100%;
		height: auto;
		padding: 1rem 4rem;
		@include flex_row(flex-start, center);
		margin-bottom: 2rem;

		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $colors, $key: "mainBlackBlue");
			margin-top: 0.2rem;
			margin-right: 1rem;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "main");
			}
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 600;
			color: #333;
			margin-right: 0.5rem;
		}
		&_type {
			font-size: 1.4rem;
			font-weight: 600;
			color: #333;
			margin-left: 0.5rem;
		}
	}

	&_repeatOptions {
		width: 100%;
		height: auto;
		padding: 0 3rem;
		&_days {
			width: 100%;
			height: auto;
			display: grid;
			grid-template-columns: repeat(auto-fit, 22%);
			grid-template-rows: repeat(auto-fit, 35%);
			justify-items: center;
			grid-row-gap: 1rem;
		}
		&_options {
			@include flex_col(flex-start, flex-start);
		}
	}

	&_repeatDesc {
		width: 100%;
		height: auto;
		padding: 0 3rem;
		& > span {
			font-weight: 500;
			font-size: 1.2rem;
			color: map-get($map: $colors, $key: "mainBlue");
		}
	}

	&_buttons {
		width: 100%;
		min-height: 5rem;
		height: auto;
		padding: 0 2rem;
		@include flex_row(flex-end, flex-end);
		flex-basis: auto;
		flex-grow: 4;
	}
}

.CycleOption {
	padding: 0 1.3rem;
	margin-bottom: 1rem;
}
