@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.YearPickerCalendar {
	position: absolute;
	top: 4rem;
	left: 0;
	width: 30rem;
	height: auto;
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "raised");
	z-index: 5;

	&_top {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;
		@include flex_row(space-between, center);

		&_heading {
			@include flex_row(flex-start, center);

			&_year {
				font-size: 1.6rem;
				font-weight: 700;
				margin-right: 0.5rem;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}
		}
		&_controls {
			margin-left: auto;

			&_icon {
				width: 2.3rem;
				height: 2.3rem;
				fill: map-get($map: $blueGreys, $key: "headings");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
				&:nth-child(2) {
					margin-left: 0.3rem;
				}
			}
		}
	}
	&_calendar {
		width: 100%;
		min-height: 25rem;
		max-height: 40rem;
		height: auto;
		display: grid;
		grid-template-columns: repeat(auto-fill, 30%);
		grid-template-rows: repeat(auto-fill, 10%);
		grid-gap: 0.5rem 0.7rem;
		justify-content: center;
		align-content: center;
	}
}
