@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlSelector {
	width: 100%;
	height: 8rem;
	background-color: #ffffff;
	@include flex_col(flex-start, center);

	&_title {
		font-size: 2rem;
	}

	&_dropdown {
		width: 100%;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
	}
}
