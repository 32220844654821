@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportsView {
	@include fill_container;
	padding: 2rem 4rem;
}

@media screen and (max-width: 550px) {
	.ReportsView {
		padding: 2rem 2rem;
	}
}
