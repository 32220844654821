@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TextInput {
	width: 100%;
	height: auto;
	border-radius: 0.5rem;
	@include flex_col(flex-start, flex-start);
	margin-bottom: 2rem;

	&_label {
		position: relative;
		font-size: 1.6rem;
		font-weight: 400;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}

	&_input {
		padding-left: 0.5rem;
		width: 100%;
		height: 3.5rem;
		border-radius: 0.5rem;
		border: none;
		outline: none;
		background-color: map-get($map: $colors, $key: "mainGrey");
		color: map-get($map: $blueGreys, $key: "headings");
		font-size: 1.6rem;
		font-weight: 400;
		&:focus,
		&:hover,
		&:focus-within {
			cursor: pointer;
			-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&::placeholder {
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "300");
		}

		// BORDER STYLES FOR REQUIRED INPUTS
		// UNCOMMENT FOR CUSTOM "REQUIRED" STYLES
		// &:required {
		//     border-left: 3px solid map-get($map: $flatColors, $key: 'mainRed');
		// }

		// &:required:invalid {
		//     border-left: 3px solid map-get($map: $flatColors, $key: 'mainRed');
		// }

		// &:required:valid {
		//     border-left: 3px solid map-get($map: $flatColors, $key: 'mainGreen');
		// }
	}

	&_requiredFlag {
		display: block;
		position: absolute;
		top: 0;
		left: 100%;
		color: map-get($map: $colors, $key: "mainRed");
		opacity: 0.6;
	}
}
