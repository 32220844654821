@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlColumnHeading {
	width: 10rem;
	height: calc(100% / 14);
	flex-grow: 1;
	@include flex_row(center, center);

	&:nth-child(even) {
		border-right: 1px solid #eaecef;
		border: 1px solid #eaecef;
	}
	&:nth-child(odd) {
		border-right: 1px solid #eaecef;
	}

	& > span {
		font-size: 1.3rem;
		font-weight: 700;
	}

	&_isDisabled {
		@extend .AdlColumnHeading;
		&:hover {
			cursor: not-allowed;
		}
		& > span {
			opacity: 0.4;
		}
	}
}

.AdlColumnHeading:last-of-type {
	border-radius: 0 0 0.5rem 0.5rem;
}
