@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ForgotPasswordModal {
	position: fixed;
	top: 0;
	left: 50%;
	max-width: 70%;
	max-height: 70%;
	width: 70vw;
	min-height: 50vh;
	height: auto;
	max-height: 80vh;
	margin-left: -35vw;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	@include slide_in_center;
	z-index: 20;

	&_top {
		width: 100%;
		height: 5rem;
		padding: 0.5rem 2rem;
		margin-bottom: 2rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "600");
		}
		&_close {
			width: 3rem;
			height: 3rem;
			border-radius: 0.5rem;
			@include flex_row(center, center);
			&:hover {
				background-color: map-get($map: $redMap, $key: "600");
				cursor: pointer;
			}
			&:hover &_icon {
				fill: #ffffff;
				cursor: pointer;
			}

			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
	}
	&_main {
		width: 100%;
		height: 85%;
		max-height: 85%;

		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}
		&::-webkit-scrollbar-thumb {
			width: 2rem;
			border-radius: 5rem;
			background-color: rgba(0, 0, 0, 0.5);
			box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		}
	}
}

@media only screen and (max-width: 800px) {
	.ForgotPasswordModal {
		top: 3rem;
		left: 50%;
		max-width: 98%;
		max-height: 85%;
		width: 98vw;
		height: 85vh;
		margin-left: -49vw;
	}
}
