@import "./mixins";
@import "./variables";

.ping {
	-webkit-animation: ping 0.8s ease-in-out infinite both;
	animation: ping 0.8s ease-in-out infinite both;
}

@-webkit-keyframes ping {
	0% {
		transform: scale(0.2);
		opacity: 0.8;
	}

	80% {
		transform: scale(1.2);
		opacity: 0;
	}

	100% {
		transform: scale(2.2);
		opacity: 0;
	}
}

@keyframes ping {
	0% {
		transform: scale(0.2);
		opacity: 0.8;
	}

	80% {
		transform: scale(1.2);
		opacity: 0;
	}

	100% {
		transform: scale(2.2);
		opacity: 0;
	}
}

@mixin pulse {
	@extend .ping;
}

.fade-out {
	-webkit-animation: fade-out 1s ease-out both;
	animation: fade-out 1s ease-out both;
}
@-webkit-keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@mixin fade_out {
	@extend .fade-out;
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		both;
	animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@mixin slide_from_right {
	@extend .slide-in-right;
}

.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 0.4s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-fwd-center {
	0% {
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		transform: translateZ(0);
		opacity: 1;
	}
}
@keyframes slide-in-fwd-center {
	0% {
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		transform: translateZ(0);
		opacity: 1;
	}
}

@mixin slide_in_center {
	@extend .slide-in-fwd-center;
}

// Task Update indicator animation
.attentionPulse {
	-webkit-animation: attentionPulse 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 6
		forwards;
	animation: attentionPulse 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 6 forwards;
}

@-webkit-keyframes attentionPulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes attentionPulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@mixin attentionPulse {
	@extend .attentionPulse;
}

@keyframes beacon {
	0% {
		-webkit-box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0.4);
		box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0.4);
		z-index: inherit;
	}
	70% {
		-webkit-box-shadow: 0 0 0 30px hsla(352, 70%, 50%, 0);
		box-shadow: 0 0 0 10px hsla(352, 70%, 50%, 0);
		z-index: inherit;
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0);
		box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0);
		z-index: inherit;
	}
}
.beacon {
	-webkit-animation: beacon 0.8s 5 forwards;
	animation: beacon 0.8s 5 forwards;
	z-index: inherit;
}
@mixin beacon {
	@extend .beacon;
}
