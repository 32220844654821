@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimePickerOption {
	width: 100%;
	height: 100%;
	@include flex_row(center, center);
	margin: 0.5rem 0;
	padding: 0.5rem 0;
	&:hover {
		cursor: pointer;
		background-color: #eaecef;
		color: map-get($map: $colors, $key: "main");
	}
	&:hover &_time,
	&:hover &_tod {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&_time {
		font-size: 1.6rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
	&_tod {
		font-size: 1.4rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
	&_isSelected {
		@extend .TimePickerOption;
		cursor: pointer;
		background-color: #eaecef;
		color: map-get($map: $colors, $key: "main");
		& > span {
			color: map-get($map: $colors, $key: "main");
		}
	}
}
