@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TaskNotesController {
	width: 100%;
	height: auto;

	&_newNote {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}
	&_existingNotes {
		width: 100%;
		height: auto;
		min-height: 10rem;
		max-height: 60rem;
		overflow-y: auto;
		/* Background tracking */
		&::-webkit-scrollbar-track {
			background-color: map-get($map: $blueGreyMap, $key: "100");
			border-radius: 5rem;
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 0.8rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "400");
			border-radius: 5rem;
		}
	}
}

.UpdateFailure {
	width: 25rem;
	height: 4rem;
	padding: 0.3rem 0.6rem;
	border-radius: 5rem;
	background-color: map-get($map: $redMap, $key: "600");
	@include flex_row(flex-start, center);

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $redMap, $key: "100");
	}
	&_msg {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "100");
		margin-left: 0.5rem;
	}
}
