@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimePicker {
	width: 20rem;
	height: auto;
	position: relative;

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-left: 0.3rem;
	}
	&_inputContainer {
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		@include flex_row(center, center);

		&_input {
			position: relative;
			width: 100%;
			height: 3.5rem;
			padding-left: 0.5rem;
			background-color: #ffffff;
			border-radius: 0.5rem;
			border: 1px solid #ccc;
			font-size: 1.6rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			outline: none;

			&:hover,
			&:focus {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
		&_icon {
			position: absolute;
			right: 3.5rem;
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");

			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
			}
		}
	}
}
