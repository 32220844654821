@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.QuickCreateTask {
	max-width: 98%;
	width: 50rem;
	height: 30rem;
	border-radius: 0.5rem;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ffffff;
	z-index: 2000;
	@include focusMode;

	&_top {
		width: 100%;
		height: 3.5rem;
		padding: 0.5rem;
		@include flex_row(space-between, center);
		margin-bottom: 6rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "600");
			padding-left: 1rem;
		}
		&_closeIcon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "500");
		}
	}
	&_main {
		width: 100%;
		height: auto;
		padding: 0 4rem;
		margin-bottom: 6rem;

		&_label {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
			margin-bottom: 0.2rem;
		}
		&_input {
			width: 100%;
			height: 3.5rem;
			border-radius: 0.5rem;
			padding-left: 0.5rem;
			background-color: #eaecef;
			font-size: 1.6rem;
			font-weight: 500;
			border: none;
			outline: none;
			&:hover,
			&:focus-within {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
			&::placeholder {
				color: map-get($map: $blueGreyMap, $key: "300");
			}
		}
		&_hint {
			margin-top: 1rem;
			font-size: 1.3rem;
			font-weight: 500;
		}
	}
	&_actions {
		width: 100%;
		height: 20rem;
		padding: 1rem 0;
		@include flex_row(flex-end, center);
	}
}
