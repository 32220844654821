@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlShiftReport {
	@include fill_container;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	margin-bottom: 2rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");

	&_top {
		width: 100%;
		padding: 1rem;
		@include flex_row(space-between, flex-start);
		margin-bottom: 4rem;

		&_title {
			font-size: 1.6rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}

		&_settings {
			width: 5rem;
			height: 100%;
			@include flex_row(space-between, flex-start);
			&_saveIcon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
			&_printIcon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
		}
	}

	&_selector {
		padding: 1rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid #ccc;
	}

	// search section (ie <TextInput/>)
	&_options {
		width: 100%;
		padding: 2rem;
		padding-top: 0.5rem;
		@include flex_col(center, center);

		&_show {
			width: max-content;
			height: max-content;
			align-self: flex-end;
			@include flex_row(flex-end, center);
			&:hover {
				cursor: pointer;
			}

			&_text {
				font-size: 1.2rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "main");
			}

			&_icon {
				margin-left: 0.5rem;
				width: 1.7rem;
				height: 1.7rem;
				fill: map-get($map: $colors, $key: "main");
			}
		}
	}

	&_content {
		width: 100%;
		height: auto;
	}
}
