@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MonthPickerCalendar {
	position: absolute;
	top: 6rem;
	left: 0;
	width: 30rem;
	height: 30rem;
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "raised");

	&_focusMode {
		@extend .MonthPickerCalendar;
		@include focusMode;
		margin-left: -15rem;
		margin-top: -15rem;
		z-index: 50;
	}

	&_top {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
		@include flex_row(space-between, center);

		&_heading {
			@include flex_row(flex-start, center);

			&_month {
				font-size: 1.6rem;
				font-weight: 700;
				margin-right: 0.5rem;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}
			&_year {
				font-size: 1.5rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}

		&_controls {
			margin-left: auto;

			&_icon {
				width: 2.3rem;
				height: 2.3rem;
				fill: map-get($map: $blueGreys, $key: "headings");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
				&:nth-child(2) {
					margin-left: 0.3rem;
				}
			}
		}
	}

	&_calendar {
		width: 100%;
		height: calc(85% - 5rem);
		padding: 1rem 0.5rem;
		display: grid;
		grid-template-columns: repeat(3, 30%);
		grid-template-rows: repeat(4, 20%);
		grid-gap: 1rem 0.7rem;
		justify-content: center;
		align-content: center;
	}

	&_today {
		width: 100%;
		height: 4rem;
		@include flex_row(center, center);

		&_btn {
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");
			font-size: 1.4rem;
			letter-spacing: 0.2rem;
			border: none;
			outline: none;
			-webkit-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}
	}
}
