@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FilterOption {
	width: 100%;
	padding: 1rem 4rem;

	&_filter {
		width: max-content;
		height: max-content;
		margin-bottom: 2rem;
	}
}
