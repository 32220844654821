@import "../../sass/variables";
@import "../../sass/mixins";

.ActualTimeReportView {
	@include fill_container;

	&_controller {
		width: 60%;
		height: auto;
		margin: 2rem auto;
		margin-bottom: 2rem;
	}
	&_panelHeading {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-bottom: 2rem;
	}
}

@media only screen and (max-width: 1100px) {
	.ActualTimeReportView {
		&_controller {
			width: 100%;
		}
	}
}
