@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ScrollToTop {
	position: fixed;
	bottom: 4rem;
	right: 4rem;
	width: 3rem;
	height: 3rem;
	@include flex_col(center, center);
	border-radius: 50%;
	border: none;
	outline: none;
	box-shadow: map-get($map: $shadows, $key: "medium");
	background-color: map-get($map: $blueGreyMap, $key: "800");
	z-index: 1000;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "heavy");
	}

	&_text {
		font-size: 0.8rem;
		font-weight: 600;
		color: #ffffff;
	}
	&_icon {
		width: 1.7rem;
		height: 1.7rem;
		fill: #ffffff;
	}
}
