@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TabsContainer {
	width: 100%;
	min-height: 15rem;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");
	@include flex_col(flex-start, flex-start);
}
