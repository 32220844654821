@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailyMultiViews {
	@include fill_container;
	padding: 2rem;

	&_viewTitle {
		font-size: 1.8rem;
		font-weight: 600;
		margin-top: 8rem;
		margin-bottom: 2rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
	&_content {
		@include fill_container;
		padding: 2rem 10rem;
	}
}
