@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CommunityInfo {
	width: 100%;
	height: auto;

	&_section {
		@include fill_container;
		@include flex_row(space-around, flex-start);
		padding: 2rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}

		&_shift {
			width: 15rem;
			height: 10rem;
			padding: 1rem;
			@include flex_col(center, center);
			border-radius: 0.5rem;
			border: 1px solid #eaecef;

			&_name {
				font-size: 1.8rem;
				font-weight: 800;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}
			&_times {
				margin-top: 0.5rem;
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}

			&:hover {
				cursor: pointer;
				border: 1px solid map-get($map: $flatColors, $key: "main");
				box-shadow: map-get($map: $shadows, $key: "light");
			}
			&:hover &_times {
				color: map-get($map: $colors, $key: "main");
			}
		}
	}
}
