@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TableHeading {
	@include fill_container;
	@include flex_row(center, center);

	&:hover &_heading_text,
	&:hover &_heading_icon {
		cursor: pointer;
	}

	&_heading {
		@include fill_container;
		@include flex_row(center, center);
		padding: 0.5rem 1rem;

		&_text {
			font-size: 1.6rem;
			font-weight: 600;
			color: #ffffff;
			margin-right: 0.5rem;
		}
		&_icon {
			width: 1.6rem;
			height: 1.6rem;
			fill: #ffffff;
			transition: all 0.3s ease-in-out;
		}
	}
}
