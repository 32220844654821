@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentSvcPlanEntry {
	width: 100%;
	height: 15rem;
	border-bottom: 1px solid #ccc;

	&:nth-child(odd) {
		background-color: map-get($map: $blueGreyMap, $key: "100");
	}

	&_inner {
		@include fill_container;
		@include flex_row(space-evenly, center);

		&_col1,
		&_col2,
		&_col3 {
			width: 33.33%;
			height: 100%;
			font-size: 1.5rem;
			font-weight: 500;
			@include flex_row(center, center);
		}
		&_col1 {
			color: map-get($map: $redMap, $key: "600");
			text-align: center;
		}
		&_col2 {
			color: map-get($map: $blueGreyMap, $key: "600");
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 0 0.3rem;
			overflow-y: scroll;
		}
		&_col3 {
			color: map-get($map: $blueGreyMap, $key: "600");
			padding: 0 0.3rem;
			overflow-y: scroll;
		}
	}
}

.GoalsUI {
	@include fill_container;
	max-width: 100%;
	height: auto;
	padding-left: 2rem;
	// overflow-y: scroll;

	&_list {
		@include fill_container;
		@include flex_col(flex-start, flex-start);
		list-style: decimal;

		&_item {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
}
.InterventionsUI {
	@include fill_container;
	max-width: 100%;
	height: auto;
	padding-left: 2rem;
	// overflow-y: scroll;

	&_list {
		@include fill_container;
		@include flex_col(flex-start, flex-start);
		list-style: decimal;

		&_item {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
}
