@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailySummaryCardCounts {
	width: 100%;
	height: auto;
	padding: 1rem;
	@include flex_row(space-evenly, flex-start);

	&_tile {
		width: 20%;
		height: auto;
		text-align: center;
		flex-grow: 1;
		flex-shrink: 1;

		&_heading {
			color: map-get($map: $blueGreys, $key: "subheadings");
			font-size: 1rem;

			& > b {
				color: map-get($map: $colors, $key: "mainBlackBlue");
				font-size: 1rem;
				font-weight: 700;
			}
			&_red {
				color: map-get($map: $colors, $key: "mainRed");
			}
			&_purple {
				color: map-get($map: $colors, $key: "main");
			}
			&_green {
				color: map-get($map: $colors, $key: "mainGreen");
			}
			&_yellow {
				color: map-get($map: $colors, $key: "altYellow");
			}
		}

		&_val {
			font-size: 1.5rem;
			color: map-get($map: $blueGreys, $key: "headings");

			&_red {
				@extend .DailySummaryCardCounts_tile_val;
				color: map-get($map: $colors, $key: "mainRed");
			}

			&_green {
				@extend .DailySummaryCardCounts_tile_val;
				color: map-get($map: $colors, $key: "mainGreen");
			}

			&_purple {
				@extend .DailySummaryCardCounts_tile_val;
				color: map-get($map: $colors, $key: "main");
			}

			&_blue {
				@extend .DailySummaryCardCounts_tile_val;
				color: map-get($map: $colors, $key: "mainBlue");
			}
			&_altYellow {
				@extend .DailySummaryCardCounts_tile_val;
				color: map-get($map: $colors, $key: "altYellow");
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.DailySummaryCardCounts {
		&_tile {
			width: max-content;
		}
	}
}
@media only screen and (max-width: 380px) {
	.DailySummaryCardCounts {
		overflow-x: auto;
		&_tile {
			width: max-content;

			&_heading {
				font-size: 0.7rem;
				& > b {
					font-size: 0.7rem;
				}
			}
		}
	}
}
