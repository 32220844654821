@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlTable {
	width: 100%;
	min-width: 80rem;
	min-height: 60rem;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	margin-bottom: 4rem;
	box-shadow: map-get($map: $shadows, $key: "medSubtle");

	&_adlTile {
		width: 10rem;
		height: 8rem;
		@include flex_row(center, center);
		border: 1px solid #eaecef;

		& > span {
			font-size: 1.8rem;
			font-weight: 700;
		}
	}
}
