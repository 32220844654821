@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TableCell {
	@include fill_container;
	text-align: center;
	font-size: 1.4rem;
	font-weight: 500;

	&_content {
		@include fill_container;
		@include flex_col(center, center); // ADDED 8/9/2022
		word-break: break-all;
		overflow-y: auto;
	}
}
