@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlShiftTile {
	height: 100%;
	padding: 0.5rem;
	@include flex_row(center, center);
	flex-grow: 2;

	&:nth-child(even) {
		border-right: 1px solid #eaecef;
		border-left: 1px solid #eaecef;
	}
}
