@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentInfoPanel {
	@include fill_container;
	font-family: map-get($map: $fonts, $key: "altSans");

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 1.5rem;
			font-weight: 800;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	&_main {
		@include fill_container;

		&_loader {
			@include flex_col(flex-start, center);
			margin-bottom: 1rem;

			&_msg {
				font-size: 1.6rem;
				font-weight: 600;
				text-align: center;
				color: map-get($map: $colors, $key: "mainPurple");
			}
		}
		&_heading {
			margin-top: 4rem;
			margin-bottom: -4rem;
			font-size: 1.6rem;
			font-size: 600;
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
}
