@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FeedbackWidget {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 30rem;
	height: 20rem;
	margin-left: -15rem;
	margin-top: -10rem;
	border-radius: 1rem;
	background-color: #ffffff;
	-webkit-box-shadow: 3px 4px 1000px 1500px rgba(0, 0, 0, 0.55);
	-moz-box-shadow: 3px 4px 1000px 1500px rgba(0, 0, 0, 0.55);
	box-shadow: 3px 4px 1000px 1500px rgba(0, 0, 0, 0.55);

	&_main {
		width: 100%;
		max-height: 11rem;
		@include flex_row(center, flex-start);
	}
}

.WidgetHeader {
	width: 100%;
	height: 5rem;
	padding: 0 1.5rem;
	@include flex_row(center, center);

	&_title {
		width: 100%;
		font-size: 2rem;
		font-weight: 800;
		color: map-get($map: $blueGreyMap, $key: "900");
		@include flex_row(center, center);
		margin-left: 1rem;
	}
	&_close {
		margin-left: auto;
		width: max-content;
		height: max-content;
		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreyMap, $key: "400");
			&:hover {
				cursor: pointer;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
	}
}

.WidgetFooter {
	width: 100%;
	height: 4rem;
	padding: 1rem 0;
	@include flex_row(center, flex-end);
	font-size: 1rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "500");
	& a {
		align-self: end;
		color: map-get($map: $blueMap, $key: "400");
		text-decoration: none;
		font-weight: 600;
	}
}
