@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportPickerDesc {
	width: 100%;
	height: auto;
	padding-left: 0.3rem;

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_desc {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
	&_group {
		width: auto;
		padding-left: 1rem;
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "headings");
		margin-bottom: 1rem;
	}
}
