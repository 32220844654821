@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ShiftDetailsTaskList {
	@include fill_container;
	padding: 2rem 0.5rem;

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
		margin-bottom: 2rem;

		&_title {
			font-size: 1.4rem;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_text {
			margin-left: 0.5rem;
			color: map-get($map: $colors, $key: "main");
			font-size: 1.3rem;
		}
	}
}
