@import "../../sass/mixins";
@import "../../sass/variables";

.YearPicker {
	width: 20rem;
	height: auto;
	position: relative;

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_input {
		width: 100%;
		height: 3.5rem;
		border-radius: 0.5rem;
		border: 1px solid #ccc;
		outline: none;
		font-size: 1.6rem;
		padding-left: 0.5rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");

		&:hover,
		&:focus {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}
	}
	&_icon {
		position: absolute;
		width: 1.6rem;
		height: 1.6rem;
		bottom: 1rem;
		right: 1rem;
		fill: map-get($map: $blueGreys, $key: "subheadings");
		&:hover {
			cursor: pointer;
			fill: map-get($map: $blueGreys, $key: "headings");
		}
	}
}
