@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewTaskList {
	width: 100%;
	max-width: 80rem;
	display: block;
	margin: 0 auto;
	height: auto;
	min-height: 40rem;
	overflow-y: scroll;

	&_settings {
		width: 100%;
		height: auto;
		min-height: max-content;
		@include flex_col(center, center);
		margin-bottom: 4rem;

		&_search {
			position: relative;
			margin-top: 1rem;
			width: auto;
			height: auto;
			@include flex_row(center, center);

			&_btn {
				position: absolute;
				right: 1rem;
				width: 2.3rem;
				height: 2.3rem;
				border-radius: 50%;
				@include flex_row(center, center);
				border: none;
				outline: none;
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $redMap, $key: "200");
				}
				&:focus-within {
					cursor: pointer;
					background-color: map-get($map: $redMap, $key: "200");
				}

				&_icon {
					width: 2rem;
					height: 2rem;
					fill: map-get($map: $redMap, $key: "600");
				}
			}
		}
	}
	&_directions {
		width: 100%;
		height: auto;
		padding: 0 4rem;
		margin: 2rem 0 4rem 0;
		@include flex_row(center, center);
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
	&_list {
		width: 100%;
		height: auto;
		min-height: 40rem;
	}
}
