@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TabButton {
	width: max-content;
	height: 100%;
	border: none;
	outline: none;
	padding: 0.5rem 1rem;
	font-size: 1.5rem;
	font-weight: 700;
	opacity: 0.6;
	background-color: transparent;
	transition: all 0.1s ease-in-out;

	&:hover {
		cursor: pointer;
		opacity: 1;
		border-bottom: 2px solid map-get($map: $colors, $key: "main");
	}
	&_isActive {
		@extend .TabButton;
		background-color: transparent;
		opacity: 1;
		color: map-get($map: $colors, $key: "main");
		font-weight: 700;
		border-bottom: 2px solid map-get($map: $colors, $key: "main");
		transition: all 0.15s ease-in-out;
		&:hover {
			cursor: pointer;
			border-bottom: 2px solid map-get($map: $colors, $key: "mainBlue");
		}
	}
}
// scale down font size on mobile screens
@media only screen and (max-width: 520px) {
	.TabButton {
		font-size: 1.3rem;
	}
}
