@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FormActions {
	width: 100%;
	height: auto;
	@include flex_row(flex-start, center);
	padding-left: 2rem;
	margin-top: 2rem;
}
