@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentTableHeadings {
	width: 100%;
	height: auto;
	min-height: 5rem;
	max-height: 8rem;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	background-color: #eaecef;
	@include flex_row(space-between, center);

	&_selectAll {
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		padding: 0.3rem;
		@include flex_row(flex-start, flex-start);

		&_label {
			color: map-get($map: $colors, $key: "main");
			font-size: 1.8rem;
			font-weight: 600;
		}
	}
}
