@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Calendar {
	min-width: 80rem;
	max-width: 100%;
	height: auto;
	background-color: #ffffff;
	border-radius: 1rem;
	margin-bottom: 6rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");
}

@media screen and (max-width: 850px) {
	.Calendar {
		min-width: 100%;
		width: 100%;
		height: auto;
	}
}
