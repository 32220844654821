@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ModalSM {
	display: block;
	position: fixed;
	width: 80vw;
	height: 70vh;
	top: 50%;
	left: 50%;
	margin-left: -40vw;
	margin-top: -35vh;
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: #ffffff;
	border-radius: 1rem 1rem 1.5rem 1.5rem;
	border-top: 5px solid map-get($map: $colors, $key: "main");
	-webkit-box-shadow: 6px 6px 42px -5px rgba(0, 0, 0, 0.75);
	box-shadow: 6px 6px 42px -5px rgba(0, 0, 0, 0.75);
	@include focusMode;
	z-index: 100;

	&::-webkit-scrollbar {
		border-radius: 5rem;
		width: 0.8rem;
		// display: none;
	}
	&::-webkit-scrollbar-thumb {
		width: 0.8rem;
		border-radius: 5rem;
		background-color: map-get($map: $blueGreys, $key: "subheadings");
	}

	&_header {
		width: 100%;
		height: auto;
		padding: 1rem 2rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}

		&_closeIcon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $colors, $key: "mainRed");
			opacity: 0.7;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				opacity: 1;
				-webkit-transform: rotateZ(360deg);
				transform: rotateZ(360deg);
			}
		}
	}

	&_main {
		@include fill_container;
		padding: 2rem 2rem;
		padding-bottom: 4rem;
	}
}

@media screen and (max-width: 500px) {
	.ModalSM {
		width: 95%;
		height: 70vh;
		@include add_center_focus(95%, 70vh);

		&_top {
			&_title {
				font-size: 2rem;
			}
		}

		&_main {
			padding: 2rem 1rem;
		}
	}
}

@media screen and (max-width: 400px) {
	.ModalSM {
		width: 98%;
		height: 75vh;
		@include add_center_focus(98%, 75vh);

		&_header {
			padding: 1rem 1rem 1rem 2rem;

			&_title {
				font-size: 1.5rem;
			}
		}
		&_main {
			padding: 2rem 1rem;
		}
	}
}
