@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ALASelectorModal {
	width: 45rem;
	height: 40rem;
	background-color: #ffffff;
	border-radius: 0.5rem;
	@include add_center_focus(45rem, 40rem);

	&_top {
		width: 100%;
		height: auto;
		max-height: 4rem;
		padding: 1rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 1.5rem;
			font-weight: 700;
			padding-left: 0.5rem;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_closeIcon {
			width: 2.5rem;
			height: 2.5rem;
			padding: 0.2rem;
			fill: map-get($map: $colors, $key: "mainRed");
			&:hover {
				cursor: pointer;
			}
		}
	}
	&_stepDesc {
		width: 100%;
		height: 15rem;
		padding: 1rem 2rem;
		@include flex_col(flex-end, center);

		&_wrapper {
			@include flex_col(center, center);
			padding: 1rem;
			border-radius: 50%;
			border: 1px solid #eaecef;
			margin-bottom: 0.5rem;

			&_icon {
				width: 5rem;
				height: 5rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
			}
			&_text {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
			}
		}
	}

	&_steps {
		width: 100%;
		height: 6rem;
		@include flex_row(center, flex-start);
	}

	// circle dots
	&_stepCounter {
		width: 100%;
		height: 8rem;
		@include flex_row(center, flex-end);

		&_stepCircle {
			display: block;
			width: 1.4rem;
			height: 1.4rem;
			background-color: #eaecef;
			border-radius: 50%;
			margin: 0 0.5rem;
			&:hover {
				cursor: pointer;
				border: 1px solid #ccc;
			}
		}
	}
}

@media only screen and (max-width: 460px) {
	.ALASelectorModal {
		width: 98vw;
		@include add_center_focus(98vw, 40rem);
	}
}
