@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Navbar {
	display: block;
	position: fixed;
	left: 0;
	max-width: 100%;
	width: 100vw;
	height: 6rem;
	background-color: #ffffff;
	font-family: map-get($map: $fonts, $key: "mainSans");
	-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	z-index: 10;

	&_wrapper {
		position: relative;
		border: none;
		outline: none;
		@include fill_container;
		z-index: 12;

		&:disabled {
			cursor: not-allowed;
		}
	}

	&_list {
		@include fill_container;
		padding: 1rem 2rem;
		list-style: none;
		@include flex_row(flex-start, center);

		&_mobile {
			display: none;

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
			}
		}

		&_item,
		&_logo,
		&_alert,
		&_item > a,
		&_logo > a {
			font-size: 1.4rem;
			font-family: map-get($map: $fonts, $key: "mainSans");
			margin-right: 0.5rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			text-decoration: none;

			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "main");
			}

			&_icon {
				@include flex_col(center, center);
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
				margin-left: auto;

				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainRed");
				}
			}
		}

		&_item {
			padding: 0.5rem 0.8rem;
			border-radius: 5rem;

			&_isActive,
			&_isActive > a {
				@extend .Navbar_list_item;
				color: map-get($map: $colors, $key: "main");
				background-color: map-get($map: $greys, $key: "chalk");
			}
			&:nth-of-type(2),
			&:nth-of-type(2) a {
				color: hsla(210, 52%, 47%, 1);
				font-weight: 700;
			}
		}

		&_alert {
			position: relative;
			margin-left: auto;
			margin-right: 1.5rem;

			&_count {
				position: absolute;
				display: block;
				bottom: 1rem;
				left: 1rem;
				width: 1.5rem;
				height: 1.5rem;
				border-radius: 50%;
				background-color: map-get($map: $colors, $key: "mainRed");
				color: #ffffff;

				&_number {
					font-size: 1rem;
					text-align: center;
				}
			}
		}

		&_logo {
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}

		&_trigger {
			position: relative;
			width: max-content;
			height: 100%;
			margin-left: auto;
			margin-right: 1rem;
			margin-top: 0.7rem;

			&::before {
				position: absolute;
				display: none;
				width: 100%;
				height: 100%;
				top: 0.7rem;
				left: -250%;
				content: "Feedback!";
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $purpleMap, $key: "600");
			}

			&:hover &::before {
				position: absolute;
				display: block;
				width: auto;
				top: 0.7rem;
				left: -250%;
				content: "Feedback!";
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $purpleMap, $key: "600");
			}
		}

		&_user {
			position: relative;
			border-radius: 50%;
			background-color: map-get($map: $blueGreys, $key: "subheadings");
			padding: 1rem;
			@include flex_col(center, center);

			&:hover {
				cursor: pointer;
			}

			&_initials {
				color: #ffffff;
				font-size: 1.4rem;
				text-align: center;

				&_admin {
					position: absolute;
					top: -0.1rem;
					right: 0;
					width: 1rem;
					height: 1rem;
					border-radius: 50%;
					background-color: map-get($map: $colors, $key: "mainRed");
					box-shadow: map-get($map: $shadows, $key: "medSubtle");
				}
			}

			&_dropdown {
				position: absolute;
				display: block;
				top: 4rem;
				right: 0;
				width: 20rem;
				// height: 33rem;
				min-height: 30rem;
				height: auto;
				border-radius: 0.5rem;
				background-color: #ffffff;
				-webkit-box-shadow: map-get($map: $shadows, $key: "raised");
				box-shadow: map-get($map: $shadows, $key: "raised");
				z-index: 100;

				&::-webkit-scrollbar {
					border-radius: 5rem;
					width: 0.4rem;
					// display: none;
				}
				&::-webkit-scrollbar-thumb {
					width: 0.4rem;
					border-radius: 5rem;
					background-color: map-get($map: $blueGreys, $key: "subheadings");
				}

				&_list {
					@include fill_container;
					padding: 1.5rem 0;
					list-style: none;

					&_admin {
						width: 100%;
						padding: 1rem;
						font-size: 1.4rem;
						font-weight: 600;

						&_indicator {
							width: 8rem;
							padding: 1rem;
							border-radius: 0.5rem;
							background-color: map-get($map: $colors, $key: "mainRed");
							color: #ffffff;
							@include flex_row(center, center);
						}
					}

					&_item,
					&_alert,
					&_logout,
					&_item > a {
						width: 100%;
						padding: 1rem;
						font-size: 1.4rem;
						font-weight: 600;
						color: map-get($map: $colors, $key: "mainBlackBlue");
						text-decoration: none;

						&:hover {
							cursor: pointer;
							color: map-get($map: $colors, $key: "main");
							background-color: map-get($map: $blueGreys, $key: "lightened");
						}

						&_icon {
							width: 1.5rem;
							height: 1.5rem;
							fill: map-get($map: $blueGreys, $key: "subheadings");

							&:hover {
								cursor: pointer;
								fill: map-get($map: $colors, $key: "mainRed");
							}
						}
					}

					&_item {
						&_icon {
							margin-left: 0.6rem;
							width: 1.5rem;
							height: 1.5rem;
							fill: map-get($map: $blueGreys, $key: "subheadings");
							margin-right: 0.3rem;
						}
					}

					&_alert {
						width: 100%;
						@include flex_row(flex-start, center);
						text-decoration: none;
						color: map-get($map: $blueGreys, $key: "headings");

						&_icon {
							margin-left: auto;
						}

						&:hover {
							cursor: pointer;
							color: map-get($map: $colors, $key: "mainRed");
						}

						&:hover &_icon {
							fill: map-get($map: $colors, $key: "mainRed");
							cursor: pointer;
						}

						&_text {
							width: 100%;
							margin-left: 1rem;
							text-decoration: none;
							color: map-get($map: $blueGreys, $key: "headings");
						}
					}

					&_logout_btn {
						margin-top: 2rem;
						padding: 1rem 2rem;
						border-radius: 0.5rem;
						font-size: 1.4rem;
						border: none;
						outline: none;
						border: 1px solid map-get($map: $colors, $key: "mainGrey");
						color: map-get($map: $colors, $key: "mainRed");

						&:hover {
							background-color: map-get($map: $colors, $key: "mainRed");
							color: #ffffff;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

._isActive {
	background-color: map-get($map: $greys, $key: "chalk");
	color: map-get($map: $colors, $key: "main");
}

.Navbar_list_trigger:hover .Navbar_list_trigger::before {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0.7rem;
	left: -250%;
	content: "Feedback!";
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $purpleMap, $key: "600");
}

.FeedbackIcon {
	width: 3rem;
	height: 3rem;
	fill: map-get($map: $blueGreyMap, $key: "600");

	&_icon {
		@include fill_container;
		object-fit: fill;
	}
	&:hover {
		cursor: pointer;
	}
}

@media screen and (max-width: 560px) {
	.Navbar {
		left: 0;
		max-width: 100%;
		width: 100vw;

		&_list {
			&_item {
				display: none;
			}

			&_mobile {
				display: inherit;
				position: relative;

				&_dropdown {
					position: absolute;
					top: 2rem;
					left: 0;
					width: 20rem;
					height: 30rem;
					overflow-y: auto;
					border-radius: 0.5rem;
					-webkit-box-shadow: map-get($map: $shadows, $key: "raised");
					box-shadow: map-get($map: $shadows, $key: "raised");
					background-color: #ffffff;

					&::-webkit-scrollbar {
						border-radius: 5rem;
						width: 0.4rem;
						// display: none;
					}
					&::-webkit-scrollbar-thumb {
						width: 0.4rem;
						border-radius: 5rem;
						background-color: map-get($map: $blueGreys, $key: "subheadings");
					}

					&_list {
						padding-top: 1rem;
						width: 100%;
						list-style: none;
						font-size: 1.3rem;
						color: map-get($map: $colors, $key: "mainBlackBlue");

						&_item {
							padding: 1rem 2rem;

							& > a {
								text-decoration: none;
								color: map-get($map: $colors, $key: "main");
								&:hover {
									cursor: pointer;
									color: map-get($map: $colors, $key: "mainBlue");
								}
							}

							&:hover {
								cursor: pointer;
								color: map-get($map: $colors, $key: "main");
								background-color: map-get($map: $blueGreys, $key: "lightened");
							}

							&_isActive {
								@extend .Navbar_list_mobile_dropdown_list_item;
								background-color: map-get($map: $colors, $key: "main");
								color: #ffffff;
								font-weight: 600;
								text-decoration: underline;
								border-radius: 5rem;
							}
						}
					}

					&_heading {
						padding: 1rem;
						font-weight: 800;
						font-size: 1.5rem;
						color: map-get($map: $colors, $key: "mainBlackBlue");
					}
				}
			}
		}
	}
}
