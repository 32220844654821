@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Week {
	@include fill_container;
	@include flex_row(flex-start, flex-start);
	border-radius: 0.5rem;
	background-color: #ffffff;
}
