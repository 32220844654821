@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentMeds {
	width: auto;

	&_cell {
		@include flex_col(flex-start, flex-start);

		& > span {
			margin-bottom: 0.5rem;
			font-size: 1.1rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
		}

		& > span > b {
			color: map-get($map: $colors, $key: "mainBlackBlue");
			font-size: 1.4rem;
		}
	}
}
