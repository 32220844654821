@import "../../sass/variables";
@import "../../sass/mixins";

.NonAuthenticatedView {
	@include fill_container;

	&_link {
		font-size: 1.3rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "main");
		margin-top: 4rem;
	}
}
