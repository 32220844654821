@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Shift {
	position: relative;
	width: 100%;
	height: calc(8.8rem / 3);
	padding: 0.3rem;
	border: none;
	outline: none;
	background-color: #eee;
	border-radius: 0.5rem;
	margin-bottom: 0.2rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}

	&_label {
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
}

.Shift_noTasks {
	@extend .Shift;
	opacity: 0.6;
}
