@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailySummaryCardHeading {
	width: 100%;
	height: auto;
	padding: 0.7rem 0;
	padding-right: 0.5rem;
	@include flex_row(flex-start, center);
	margin-bottom: 0.3rem;

	&_adl {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 1px solid map-get($map: $purpleMap, $key: "600");
		background-color: map-get($map: $blueGreyMap, $key: "100");
		margin-right: 0.5rem;
		margin-left: -0.7rem;
		@include flex_row(center, center);
		box-shadow: map-get($map: $shadows, $key: "subtle");

		&_icon {
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $purpleMap, $key: "600");
		}
	}

	&_heading {
		width: auto;

		&_title,
		&_adl {
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "headings");
		}

		&_adl {
			color: map-get($map: $purpleMap, $key: "700");
			font-weight: 700;
		}

		&_title {
			font-size: 1rem;
		}
	}

	&_completedBadge {
		position: absolute;
		top: 10%;
		left: 35%;
		width: max-content;
		height: max-content;
		width: auto;
		padding: 0.5rem 0.8rem;
		font-size: 1.3rem;
		border-radius: 5rem;
		background-color: map-get($map: $flatColors, $key: "mainGreen");
		opacity: 1;
		// color: map-get($map: $colors, $key: "mainGreen");
		color: map-get($map: $colors, $key: "mainBlackBlue");
		border: 1px solid map-get($map: $colors, $key: "mainGreen");
		z-index: 5;
	}

	&_notes {
		margin-left: auto;
		align-self: flex-start;
		color: map-get($map: $colors, $key: "main");
		font-weight: 700;
		font-family: map-get($map: $fonts, $key: "mainSans");
		&:hover {
			cursor: pointer;
			color: map-get($map: $colors, $key: "mainBlue");
		}
	}

	&_icon {
		position: relative;
		margin-left: auto;
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $blueGreys, $key: "subheadings");

		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "main");
		}
	}
}

@media only screen and (max-width: 430px) {
	.DailySummaryCardHeading {
		&_completedBadge {
			font-size: 1rem;
		}
	}
}
