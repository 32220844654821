@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CalendarNav {
	width: 100%;
	height: 7rem;
	font-family: map-get($map: $fonts, $key: "altSans");
	border-radius: 5rem;
	border-top: 1px solid #eaecef;
	border-bottom: 1px solid #eaecef;
	box-shadow: map-get($map: $shadows, $key: "light");

	&_list {
		@include fill_container;
		@include flex_row(space-between, center);
		padding: 0.5rem 1rem;
		list-style: none;

		&_item {
			width: auto;

			&_month {
				font-size: 2rem;
				font-weight: 700;
				color: map-get($map: $colors, $key: "main");

				&_btn {
					border: none;
					outline: none;
					font-size: 2rem;
					font-weight: 700;
					color: map-get($map: $colors, $key: "main");
					background-color: transparent;
					&:hover,
					&:focus {
						cursor: pointer;
						color: map-get($map: $colors, $key: "mainBlue");
					}
				}

				& > b:last-of-type {
					font-weight: 500;
					color: map-get($map: $blueGreys, $key: "subheadings");
				}
			}

			&_btn {
				width: 3rem;
				height: 3rem;
				@include flex_row(center, center);
				border-radius: 50%;
				border: 1px solid map-get($map: $colors, $key: "main");
				box-shadow: map-get($map: $shadows, $key: "light");
				background-color: #ffffff;
				outline: none;
				&:hover,
				&:focus {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "main");
					box-shadow: map-get($map: $shadows, $key: "medSubtle");
				}
				&:focus &_arrow,
				&:hover &_arrow {
					fill: #ffffff;
				}

				&_arrow {
					width: 2.5rem;
					height: 2.5rem;
					fill: map-get($map: $colors, $key: "main");
					// &:hover {
					// 	cursor: pointer;
					// 	fill: #ffffff;
					// }
					&:first-of-type {
						margin-bottom: 0.1rem;
						margin-right: 0.2rem;
					}
					&:first-of-type {
						margin-bottom: 0.1rem;
						margin-left: 0.2rem;
					}
				}

				&:disabled {
					opacity: 0.4;
					cursor: not-allowed;
				}
			}
		}
	}
}

@media screen and (min-width: 1250px) {
	.CalendarNav {
		&_list {
			&_item {
				&_month {
					font-size: 2.6rem;
					font-weight: 700;
					color: map-get($map: $colors, $key: "main");

					&_btn {
						border: none;
						outline: none;
						font-size: 2.6rem;
						font-weight: 700;
						color: map-get($map: $colors, $key: "main");
						&:hover,
						&:focus {
							cursor: pointer;
							color: map-get($map: $colors, $key: "mainBlue");
						}
					}

					& > b:last-of-type {
						font-weight: 500;
						color: map-get($map: $blueGreys, $key: "subheadings");
					}
				}
			}
		}
	}
}
