@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PastDuePanel {
	min-width: 25rem;
	width: 100%;
	max-height: 100%;
	height: 100%;
	padding: 2rem 1rem;
	padding-bottom: 4rem;
	border-radius: 0.5rem;
	border-bottom: 10rem solid map-get($map: $blueGreys, $key: "main");
	// background-color: map-get($map: $blueGreys, $key: "main");
	background-color: #f8f8ff;
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	position: relative;
	z-index: 0;

	&_controls {
		width: 100%;
		@include flex_row(flex-end, center);
		margin-bottom: 2rem;

		& > span {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "main");
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
				text-decoration: underline;
			}
		}
	}

	&_search {
		width: 100%;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		margin-bottom: 4rem;
	}

	&_entries {
		width: 100%;
		height: 100%;
		padding-bottom: 3rem;
		overflow-y: auto;
		position: relative;
		z-index: 5;
		& > hr {
			opacity: 0.6;
			margin: 1rem 0;
		}
	}

	&_viewMore {
		width: 100%;
		height: 100%;
		padding: 0.2rem 0.5rem;
		@include flex_row(flex-start, center);

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: #ffffff;
		}
	}
}
