@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ADLNotes {
	@include fill_container;
	padding: 4rem 2rem;

	&_group {
		width: 100%;
		height: auto;

		&_headings {
			@extend .ADLNotes_group;
			@include flex_row(space-between, flex-end);

			&_date {
				font-size: 1.5rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}

		&_title {
			font-size: 2.2rem;
			color: map-get($map: $colors, $key: "mainBlue");
			align-self: flex-end;
		}
		&_subtitle {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
			margin-bottom: 1rem;
			text-decoration: underline;
		}
		&_text {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "headings");
			width: 100%;
			height: auto;
			padding: 1rem;
			background-color: #eaecef;
		}

		&_form {
			width: 100%;
			margin-top: 2rem;

			&_buttons {
				@include flex_row(flex-start, center);
			}

			&_icon {
				width: 1.4rem;
				height: 1.4rem;
				fill: #ffffff;
				margin-right: 0.3rem;
			}
		}
	}
	&_independent {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "headings");
		margin-bottom: 4rem;
		& > b {
			font-weight: 800;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}
}

@media screen and (max-width: 500px) {
	.ADLNotes {
		&_group {
			&_title {
				font-size: 1.6rem;
			}
		}
	}
}
