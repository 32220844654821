@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Table {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	border-radius: 0.5rem;
	// border: 1px solid #eaecef;

	&_top {
		width: 100%;
		height: 5rem;
		@include flex_row(space-between, center);
		border-radius: 0.5rem 0.5rem 0 0;
		padding: 0.5rem 1rem;
		border: 1px solid #eaecef;
		margin-bottom: 1rem;

		&_title {
			font-size: 2rem;
			font-weight: 600;
		}
		&_rowCount {
			font-size: 1.7rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
}
