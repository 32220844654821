@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PastDueList {
	width: 100%;
	height: auto;

	&_heading {
		@include flex_row(flex-start, center);

		&_title {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			margin-left: 0.5rem;
			& > b {
				color: map-get($map: $colors, $key: "mainRed");
			}
		}
		&_count {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainRed");
			margin-left: 1rem;
		}
	}

	&_collapseList {
		width: 100%;
		height: auto;

		&_btn {
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");
			font-size: 1.3rem;
			font-weight: 500;
			border: none;
			outline: none;
			margin: 0.5rem 0;
			padding: 0.3rem 0.5rem;
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
			&:hover & > b {
				color: map-get($map: $colors, $key: "mainBlue");
			}
			& > b {
				font-weight: 800;
				color: map-get($map: $colors, $key: "mainRed");
				text-decoration: underline;
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainBlue");
				}
			}
		}
	}

	&_entries {
		@include fill_container;
	}
}
