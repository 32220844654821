@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TaskNoteContent {
	width: 100%;
	height: auto;
	// padding: 0 1rem;
	padding-bottom: 2rem;

	&_content {
		width: 100%;
		height: auto;
		padding-top: 1rem;
		padding-left: 4rem;
		padding-right: 1rem;
	}
	&_actions {
		width: 100%;
		height: auto;
		padding-left: 4rem;
		@include flex_row(flex-start, center);
		padding-top: 1rem;
	}
}

.NoteContent {
	width: 100%;
	height: auto;
	// padding-top: 1rem;
	// padding-left: 4rem;
	// padding-right: 1rem;
	font-size: 1.5rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "800");
}
