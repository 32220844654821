@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FloatingFiltersButton {
	width: 5rem;
	height: 5rem;
	position: fixed;
	top: 20vh;
	right: 1rem;
	border-radius: 50%;
	box-shadow: map-get($map: $shadows, $key: "raised");
	z-index: 2;

	&_floatingBtn {
		@include fill_container;
		@include flex_row(center, center);
		border-radius: 50%;
		border: none;
		outline: none;
		background-color: map-get($map: $colors, $key: "main");
		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}
		&_close {
			@extend .FloatingFiltersButton_floatingBtn;
			background-color: map-get($map: $colors, $key: "mainRed");
		}

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: #ffffff;
			background-color: transparent;
		}
	}
}

@media only screen and (max-width: 750px) {
	.FloatingFiltersButton {
		right: 1.4rem;
		width: 3.8rem;
		height: 3.8rem;

		&_floatingBtn {
			@include fill_container;

			&_icon {
				width: 1.9rem;
				height: 1.9rem;
			}
		}
	}
}
