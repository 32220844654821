@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlScheduleView {
	width: 100%;
	padding: 3rem;

	&_title {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "main");
	}
	&_desc {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
}
