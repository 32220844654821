@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailyView {
	@include fill_container;
	padding: 2rem;

	&_viewTitle {
		font-size: 1.8rem;
		font-weight: 600;
		margin-top: 8rem;
		margin-bottom: 2rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}

	&_communityInfo {
		width: 100%;
		height: auto;
		@include flex_row(center, flex-start);
		margin-bottom: 2rem;
	}

	&_LOA {
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $colors, $key: "mainRed");
	}

	&_content {
		width: 100%;
		height: auto;
		@include flex_col(center, center);

		&_cards {
			@include fill_container;
			@include flex_row(space-evenly, flex-start, wrap);
		}
		&_list {
			width: 100%;
			height: auto;
			padding: 4rem 6rem;
		}
	}
}

// CREATE TASK BUTTON ICON
.CreateTaskIcon {
	width: 2.5rem;
	height: 2.5rem;
	fill: #ffffff;
}

@media only screen and (max-width: 500px) {
	.DailyView {
		padding: 2rem 0.5rem;

		&_content_list {
			padding: 0 0.5rem;
		}
	}
	.CreateTaskIcon {
		width: 2rem;
		height: 2rem;
	}
}
