@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.PasswordInput {
	width: 100%;
	height: auto;
	margin-bottom: 2rem;

	&_label {
		font-size: 1.6rem;
		font-weight: 400;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_wrapper {
		@include flex_row(flex-start, center);
		padding: 0 0.5rem;
		border-radius: 0.5rem;
		background-color: map-get($map: $colors, $key: "mainGrey");
		&:focus,
		&:hover,
		&:focus-within {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&_input {
			width: 100%;
			height: 3.5rem;
			border: none;
			outline: none;
			border-radius: 0.5rem;
			background-color: map-get($map: $colors, $key: "mainGrey");
			padding-left: 0.2rem;
			font-size: 1.6rem;
			font-weight: 500;
		}
		&_icon {
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");
			&:hover {
				cursor: pointer;
				fill: map-get($map: $blueGreys, $key: "headings");
			}
		}
	}
}
