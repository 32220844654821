@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AuthenticatedView {
	width: 100%;
	height: 100%;

	&_dashboard {
		width: 100%;
		height: max-content;

		&_header {
			width: 100%;
			height: max-content;
			@include flex_col(center, center);
		}

		&_main {
			@include fill_container;
		}
	}
	&_nav {
		width: 100%;
		height: 10rem;
		min-height: 10rem;
		max-height: 10rem;
	}
}

@media only screen and (max-width: 340px) {
	.AuthenticatedView {
		&_dashboard {
			&_header {
				width: 90%;
				margin: 0 auto;
				@include flex_row(center, center);
			}
		}
	}
}
