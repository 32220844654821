@import "../../sass/variables";
@import "../../sass/mixins";

.CustomDropdownSM {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	opacity: 1;

	&_label {
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_inputWrapper {
		position: relative;
		width: max-content;
		height: max-content;

		&_input {
			width: 100%;
			height: 2rem;
			padding-left: 0.3rem;
			font-size: 1.4rem;
			font-weight: 500;
			border-radius: 0.5rem;
			border: 1px solid #ccc;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			outline: none;

			&:hover,
			&:focus {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
			&::placeholder {
				font-size: 1.2rem;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
	}
	&_options {
		position: absolute;
		top: 2.5rem;
		right: 0.3rem;
		width: 100%;
		// width: 10rem;
		height: auto;
		max-height: 15rem;
		border-radius: 0.5rem;
		padding: 0.3rem 0;
		overflow-y: auto;
		background-color: #ffffff;
		box-shadow: map-get($map: $shadows, $key: "heavySubtle");
		z-index: 10;

		&_list {
			@include fill_container;
			list-style: none;

			&_item {
				width: 100%;
				padding: 0.3rem 1rem;
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-bottom: 0.5rem;
				outline: none;

				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "main");
					background-color: map-get($map: $greys, $key: "chalk");
				}
				&:focus,
				&:focus-visible,
				&:focus-within {
					cursor: pointer;
					color: map-get($map: $colors, $key: "main");
					background-color: map-get($map: $greys, $key: "chalk");
				}
				&_focus {
					@extend .CustomDropdownSM_options_list_item;
					cursor: pointer;
					color: map-get($map: $colors, $key: "main");
					background-color: map-get($map: $greys, $key: "chalk");
				}
			}
		}
	}
	&_closeIcon {
		position: absolute;
		bottom: 0.15rem;
		right: 0.3rem;
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $blueGreys, $key: "headings");
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainRed");
		}
	}
}
