@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Beacon {
	position: absolute;
	bottom: 0.5rem;
	left: 0.5rem;
	width: max-content;
	height: max-content;
	border-radius: 50%;
	background-color: map-get($map: $colors, $key: "mainRed");
	@include beacon;

	&_inner {
		@include fill_container;
		border-radius: 50%;
		background-color: transparent;
	}
}
