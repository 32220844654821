@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.DateRangePickerSM {
	width: 27rem;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;

	&_label {
		width: 100%;
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-left: 0.3rem;
	}

	&_inputs {
		position: relative;
		width: 100%;
		height: 3.5rem;
		@include flex_row(flex-start, center);
		border-radius: 0.5rem;
		border: 1px solid #ccc;
		outline: none;
		font-size: 1.6rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
		&:hover,
		&:focus {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&_startDate,
		&_endDate {
			height: 100%;
			border-radius: 0.5rem;
			border: none;
			outline: none;
			padding-left: 0.5rem;
			font-size: 1.6rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");

			&:hover {
				cursor: pointer;
			}
			&::-webkit-input-placeholder {
				font-size: 1.4rem;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
			&::-moz-placeholder {
				font-size: 1.4rem;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
			&:-ms-input-placeholder {
				font-size: 1.4rem;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
			&::-ms-input-placeholder {
				font-size: 1.4rem;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
			&::placeholder {
				font-size: 1.4rem;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
		&_startDate {
			width: 46%;
			border-radius: 0.5rem 0 0 0.5rem;
		}
		&_endDate {
			width: 54%;
			border-radius: 0 0.5rem 0.5rem 0;
		}

		&_icon {
			position: absolute;
			width: 1.6rem;
			height: 1.6rem;
			bottom: 0.8rem;
			right: 1rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");
			z-index: 2;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $blueGreys, $key: "headings");
			}
		}
	}
	&_error {
		color: map-get($map: $colors, $key: "mainRed");
		font-size: 1.3rem;
		margin-bottom: 2rem;
	}
}
