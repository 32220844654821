@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlColumnHeadings {
	width: 10rem;
	min-height: 70rem;
	border-radius: 0 0 0 0.5rem;
	border-left: 1px solid #eaecef;
	@include flex_col(flex-start, flex-start);

	&_loading {
		@include flex_col(center, center);
	}
}
