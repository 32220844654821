@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TabButtons {
	width: 100%;
	height: auto;
	padding: 1rem 3rem;
	@include flex_row(space-between, center);
	border-bottom: 1px solid #ccc;
}
