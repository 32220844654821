@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TaskNotesEntry {
	width: 100%;
	height: auto;
	margin: 2rem 0;

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
	}
}

// TOP SECTION
.NotesUserBadge {
	position: relative;
	min-width: 3rem;
	width: 3rem;
	min-height: 3rem;
	height: 3rem;
	@include flex_row(center, center);
	border-radius: 50%;
	box-shadow: map-get($map: $shadows, $key: "subtle");
	background-color: map-get($map: $purpleMap, $key: "700");

	&_initials {
		@include fill_container;
		@include flex_row(center, center);
		background-color: transparent;
		font-size: 1.4rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "50");
	}
}
.NotesUserName {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	// margin-left: 0.5rem;
	margin: 0 1rem;

	&_user {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}
.NotesTimeStamp {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	font-size: 1.4rem;
	font-weight: 500;
	font-family: map-get($map: $fonts, $key: "altSans");
	color: map-get($map: $blueGreyMap, $key: "500");
	margin-right: 1rem;
}
.WasEdited {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "500");

	&_desc {
		width: auto;

		&_label {
			font-family: map-get($map: $fonts, $key: "mainSans");
		}
		&_timestamp {
			font-size: 1.3rem;
			font-family: map-get($map: $fonts, $key: "altSans");
			margin-left: 0.5rem;
		}
		&_editedBy {
			font-size: 1.3rem;
			font-family: map-get($map: $fonts, $key: "altSans");
			margin-left: 0.5rem;
		}
	}
}
