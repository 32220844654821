@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSecurityAnswer {
	width: 100%;
	height: max-content;
	margin-top: 2rem;
	margin-bottom: 0.5rem;

	&_wrapper {
		width: 100%;

		&_label {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}

		&_input {
			width: 100%;
			height: 3.5rem;
			border-radius: 0.5rem;
			background-color: #ffffff;
			outline: none;
			border: 1px solid map-get($map: $blueGreyMap, $key: "300");
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			padding-left: 0.5rem;

			&:focus-within,
			&:hover {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}

			&::placeholder {
				color: map-get($map: $blueGreyMap, $key: "300");
			}
			&:read-only,
			&:disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}
		}
	}
}
