@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PageNotFound {
	@include fill_container;
	padding: 2rem;

	&_title,
	&_subtitle {
		font-size: 3rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "mainSans");
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}

	&_subtitle {
		font-size: 2rem;
		font-weight: 400;
	}

	&_redirects {
		margin-top: 10rem;
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);

		&_redirectBtn {
			padding: 1.2rem 2.4rem;
			border-radius: 0.5rem;
			border: none;
			outline: none;
			font-size: 1.4rem;
			font-weight: 500;
			margin-right: 2rem;
			background-color: map-get($map: $colors, $key: "mainBlackBlue");
			color: map-get($map: $blueGreys, $key: "light");

			&:hover {
				cursor: pointer;
				-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
				box-shadow: map-get($map: $shadows, $key: "medSubtle");
			}
		}
	}
}
