@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewTaskListEntry {
	position: relative;
	width: 100%;
	height: 8rem;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
	background-color: map-get($map: $blueGreyMap, $key: "100");
	@include flex_row(flex-start, center);
	margin: 1rem 0;
	transition: all 0.2s ease-in-out;
	&:hover {
		z-index: 100;
		opacity: 1;
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "focusMode");
	}

	&_photo {
		width: 10rem;
		max-width: 10rem;
		min-width: 10rem;
		height: 100%;
		@include flex_col(flex-start, center);
		margin-top: -1rem;
		// margin-top: -1.5rem;
		margin-right: 3rem;
	}
	&_details {
		width: 85%;
		height: 100%;
		padding: 0.5rem 0.5rem;
	}
	&_actions {
		width: auto;
		height: 100%;
		@include flex_col(center, center);
		padding: 0.3rem 0.4rem;
	}

	// STATUS STYLES
	&_isCompleted {
		@extend .TimeViewTaskListEntry;
		opacity: 0.4;
		border-left: 4px solid map-get($map: $colors, $key: "mainGreen");
		z-index: 1;
		&:hover {
			opacity: 0.8;
			border-left: 4px solid map-get($map: $flatColors, $key: "mainGreen");
		}
	}
	&_isMissedEvent {
		@extend .TimeViewTaskListEntry;
		border-left: 4px solid map-get($map: $flatColors, $key: "mainRed");
		transition: all 0.3 ease-in-out;
		z-index: 1;
		&:hover {
			border-left: 4px solid map-get($map: $colors, $key: "mainRed");
		}
	}
	&_isPastDue {
		@extend .TimeViewTaskListEntry;
		border-left: 4px solid map-get($map: $colors, $key: "main");
		z-index: 1;
		&:hover {
			cursor: pointer;
			border-left: 4px solid map-get($map: $colors, $key: "main");
		}
	}
	&_isNotCompleted,
	&_isPending {
		@extend .TimeViewTaskListEntry;
		cursor: pointer;
		border-left: 4px solid map-get($map: $colors, $key: "altYellow");
		z-index: 1;
	}
}

.ResidentName {
	width: 100%;
	height: 3rem;
	// @include flex_row(center, flex-end, nowrap);
	display: block;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin-top: 0.2rem;
	overflow-x: hidden;
	overflow-y: visible;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	padding: 0 0.3rem;

	// /* Background tracking */
	// &::-webkit-scroll-track {
	// 	-webkit-appearance: none;
	// }
	// /* Sets width/height of 'thumb' & hides default */
	// &::-webkit-scrollbar {
	// 	appearance: none;
	// 	-webkit-appearance: none;
	// 	// width: 0.8rem;
	// }
	// &::-webkit-scrollbar-thumb {
	// 	appearance: none;
	// }
}

.TaskInfo {
	width: 100%;
	height: 100%;
	@include flex_row(space-evenly, center);
	// @include flex_col(center, flex-start);
	// align-self: flex-start;
	// margin-top: -2rem;

	&_name {
		width: 60%;
		// USE flex_row for inline text
		// @include flex_row(flex-start, center);

		// USE flex_col for stacked text
		@include flex_col(center, flex-start);
	}

	&_time {
		width: 40%;
		@include flex_row(center, center);
		// width: auto;
		// margin-right: auto;
		// @include flex_row(flex-start, center);
	}
}
.TaskADL {
	width: auto;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "400");
	margin: 0.5rem 0;
	margin-right: 0.5rem;
	margin-bottom: 0;
	margin-top: 0;
}
.TaskName {
	// width: auto;
	width: 100%;
	overflow-x: scroll;
	font-size: 1.4rem;
	font-weight: 700;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin: 0.5rem 0;
}

.TaskADLIcon {
	width: auto;
	margin-right: 1rem;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "800");
	}
}
.TaskTime {
	width: auto;
	min-width: max-content;
	font-size: 1.4rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
	font-family: map-get($map: $fonts, $key: "altSans");
}

.CompletedIcon {
	position: absolute;
	top: 0.3rem;
	left: 0.3rem;
	width: auto;
	height: auto;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $greenMap, $key: "500");
	}
}

.MenuIcon {
	width: auto;
	height: auto;
	align-self: flex-end;
	justify-self: flex-end;
	background-color: transparent;
	border: none;
	outline: none;
	margin-bottom: auto;

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $blueGreyMap, $key: "500");
		&:hover {
			cursor: pointer;
			fill: map-get($map: $purpleMap, $key: "700");
		}
	}
}
.AdvancedButton {
	position: relative;
	width: auto;
	height: auto;
	padding: 0.2rem 0.4rem;
	border-radius: 0.5rem;
	border: none;
	outline: none;
	background-color: transparent;
	z-index: 100;
	&:hover {
		cursor: pointer;
	}

	&_text {
		text-align: center;
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $purpleMap, $key: "700");
	}
}

@media only screen and (max-width: 800px) {
	.TimeViewTaskListEntry {
		height: 15rem;

		&_photo {
			width: 15rem;
			@include flex_col(center, center);
			margin-right: 1rem;
		}
	}
	.TaskInfo {
		width: 100%;
		height: 100%;

		&_time {
			margin-left: 3rem;
		}
	}
	.TaskTime {
		width: auto;
		min-width: max-content;
	}
}
@media only screen and (max-width: 550px) {
	.TimeViewTaskListEntry {
		width: 100%;

		&_photo {
			width: 8rem;
			max-width: 8rem;
			min-width: 8rem;
		}

		&_details {
			width: 100%;
			height: 8rem;
		}
	}
	.ResidentName {
		font-size: 1.3rem;
		margin-top: 0.5rem;
	}
	.TaskInfo {
		width: 100%;
		max-width: 100%;

		&_name {
			width: 85%;
		}
		&_time {
			width: auto;
			margin-left: auto;
			@include flex_row(flex-end, center);
		}
	}
	.TaskName {
		width: 100%;
		font-size: 1.3rem;
	}
	.TaskADL {
		font-size: 1.3rem;
	}
	.TaskTime {
		font-size: 1.3rem;
	}

	.MenuIcon {
		width: auto;
		height: auto;
		align-self: flex-end;
		justify-self: flex-end;
		background-color: transparent;
		border: none;
		outline: none;
		margin-bottom: auto;

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreyMap, $key: "500");
			&:hover {
				cursor: pointer;
				fill: map-get($map: $purpleMap, $key: "700");
			}
		}
	}
	.AdvancedButton {
		position: relative;
		width: auto;
		height: auto;
		padding: 0.2rem 0;
		border-radius: 0.5rem;
		border: none;
		outline: none;
		background-color: transparent;
		z-index: 100;
		&:hover {
			cursor: pointer;
		}

		&_text {
			text-align: center;
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "700");
		}
	}
}
