@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportTypeController {
	@include fill_container;
	height: auto;

	&_about {
		width: 100%;
		padding: 2rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "main");
			text-shadow: 2px 2px 2px rgba(150, 150, 150, 0.8);
		}
	}

	&_selector {
		width: 100%;
		height: auto;
	}
	&_settingsPanel {
		width: 100%;
		margin-bottom: 2rem;
	}
	&_reportPanel {
		margin-top: 2rem;
	}
}
