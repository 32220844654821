@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CalendarHeading {
	width: 100%;
	height: 10rem;

	&_headings {
		@include fill_container;
		@include flex_row(flex-start, flex-start);
		flex-basis: 1;
		-webkit-box-flex: 1;
		flex-grow: 1;
		font-family: map-get($map: $fonts, $key: "altSans");

		&_category {
			@include flex_row(center, center);
			width: calc(100% / 8);
			height: 100%;
			padding: 0.5rem;
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			border-radius: 0 0 0 1rem;
			border: 1px solid #eaecef;
		}
		&_item {
			position: relative;
			width: calc(100% / 8);
			height: 100%;
			padding: 0.5rem;
			@include flex_col(center, center);
			border: 1px solid #eaecef;

			&_date {
				font-size: 2.2rem;
				font-weight: 700;
				color: map-get($map: $colors, $key: "mainBlackBlue");
				margin-bottom: 0.2rem;
				&_isToday {
					@extend .CalendarHeading_headings_item_date;
					color: map-get($map: $colors, $key: "main");
				}
			}
			&_day {
				font-size: 1.3rem;
				font-weight: 400;
				color: map-get($map: $blueGreys, $key: "subheadings");

				&_isToday {
					@extend .CalendarHeading_headings_item_day;
					color: map-get($map: $colors, $key: "main");
				}
			}

			&_diffMonth {
				@extend .CalendarHeading_headings_item;
				opacity: 0.5;
			}

			&:last-of-type {
				border-right: 1px solid #eaecef;
				border-radius: 0 0 1rem 0;
			}
			&_isToday {
				@extend .CalendarHeading_headings_item;
				background-color: map-get($map: $flatColors, $key: "altPurple");
				border-radius: 1rem;
			}
		}
	}
}

@media screen and (min-width: 1250px) {
	.CalendarHeading {
		&_headings {
			&_item {
				&_date {
					font-size: 2.6rem;
				}
				&_day {
					font-size: 1.8rem;
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {
	.CalendarHeading {
		&_headings {
			&_category {
				font-size: 1.8rem;
			}
			&_item {
				&_date {
					font-size: 2rem;
				}
				&_day {
					font-size: 1.2rem;
				}
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.CalendarHeading {
		&_headings {
			&_category {
				font-size: 1.5rem;
			}
			&_item {
				&_date {
					font-size: 1.7rem;
				}
				&_day {
					font-size: 1rem;
				}
			}
		}
	}
}
