@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TableRow {
	@include fill_container;
	// height: 5rem;
	height: 10rem;
	padding: 0.3rem 1rem;

	&_cells {
		@include fill_container;
		@include flex_row(flex-start, center);
	}

	&:nth-child(even) {
		background-color: #eaecef;
	}
}
