@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ShiftStatusBadge {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	position: absolute;
	display: block;
	top: 0.5rem;
	@include flex_row(center, center);

	&_count {
		font-size: 1rem;
		font-weight: 600;
	}
}
