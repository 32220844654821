@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ShiftHeading {
	@include fill_container;
	width: calc(100% / 4);
	padding: 0.5rem;
	@include flex_col(center, flex-start);
	-webkit-box-flex: 2;
	flex-grow: 2;
	border: 1px solid #eaecef;

	&_shiftName {
		width: 100%;
		height: 55%;
		@include flex_row(center, center);
		border-bottom: 1px solid #eaecef;
		& > span {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}
	&_shiftRange {
		position: relative;
		width: 100%;
		height: 45%;
		@include flex_row(center, center);
		border-top: 1px solid #eaecef;
		& > span {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}

		&_edit {
			position: absolute;
			top: 1.3rem;
			right: 1rem;
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			border: 1px solid map-get($map: $colors, $key: "main");
			@include flex_row(center, center);
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $colors, $key: "main");
				border: 1px solid map-get($map: $colors, $key: "main");
			}
			&:hover &_editIcon {
				cursor: pointer;
			}

			&_editIcon {
				width: 1.6rem;
				background-color: transparent;
				height: 1.6rem;
				fill: map-get($map: $colors, $key: "main");
				&:hover {
					cursor: pointer;
					fill: #ffffff;
				}
			}
		}

		&_cancel {
			position: absolute;
			bottom: 0;
			right: 1rem;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			border: 1px solid #eaecef;
			@include flex_row(center, center);
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				border: 1px solid map-get($map: $colors, $key: "mainRed");
			}
			&:hover &_cancelIcon {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}

			&_cancelIcon {
				width: 1.2rem;
				height: 1.2rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
				&:hover {
					cursor: pointer;
					fill: 1px solid map-get($map: $colors, $key: "main");
				}
			}
		}
	}
}
