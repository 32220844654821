@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Embed {
	width: auto;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");
	@include flex_col(center, center);

	&_mirror {
		@include fill_container;
		border-radius: 0.5rem;
	}
	&_placeholder {
		@include flex_col(center, center);
		font-size: 1.8rem;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-bottom: 1rem;
	}
	&_fallback {
		width: auto;
		height: auto;
		border-radius: 0.5rem;
	}
}

@media screen and (max-width: 540px) {
	.Embed {
		@include flex_col(center, center);
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		border-radius: 5rem;
		box-shadow: map-get($map: $shadows, $key: "subtle");

		&_mirror {
			@include fill_container;
			border-radius: 0.5rem;
		}
	}
}
