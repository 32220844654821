@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentTableHeading {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	padding: 0.3rem;
	@include flex_row(center, center);

	&_heading {
		@include flex_row(flex-start, center);

		&:hover {
			cursor: pointer;
			color: map-get($map: $colors, $key: "main");
		}
		&:hover &_icon {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "main");
		}

		&_title {
			font-size: 1.7rem;
			font-weight: 600;
		}
		&_icon {
			margin-left: 0.3rem;
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $colors, $key: "mainBlackBlue");
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;

			&_rotated {
				@extend .ResidentTableHeading_heading_icon;
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
}
