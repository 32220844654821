@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportViewerTableCell {
	// @include fill_container;
	width: 100%;
	height: 100%;
	max-height: fit-content;
	text-align: center;
	font-size: 1.4rem;
	font-weight: 500;

	&_content {
		@include fill_container;
		@include flex_col(center, center);
		word-break: break-all;
		// text-overflow: clip;
		overflow-y: scroll;
	}
}
