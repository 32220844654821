@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportsController {
	@include fill_container;

	&_btn {
		padding: 1.2rem 2rem;
		border-radius: 5rem;
		background-color: map-get($map: $colors, $key: "main");
		color: #ffffff;
		box-shadow: map-get($map: $shadows, $key: "subtle");
		@include flex_row(flex-start, center);
		font-size: 1.6rem;
		font-weight: 700;
		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "heavySubtle");
		}
		&_icon {
			width: 2rem;
			height: 2rem;
			margin-right: 0.5rem;
			fill: #ffffff;
		}
	}

	&_content {
		@include fill_container;
	}
}
