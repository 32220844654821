@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportOtherOptions {
	width: 100%;
	height: auto;

	&_strikeouts {
		width: 100%;
		height: auto;
		margin: 2rem 0;
	}
	&_timezone {
		width: 100%;
		height: auto;
		margin: 2rem 0;

		&_selector {
			padding-left: 4rem;
		}
	}
}
