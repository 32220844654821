@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TaskNoteActions {
	width: 100%;
	height: auto;
	margin-top: 0.5rem;

	&_inner {
		width: 100%;
		height: auto;
		padding-left: 3.5rem;
		@include flex_row(flex-start, center);

		&_bullet {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
}

.EditButton {
	width: auto;
	height: auto;
	font-size: 1.5rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "400");
	border: none;
	outline: none;
	background-color: transparent;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	&:hover {
		cursor: pointer;
		color: map-get($map: $purpleMap, $key: "700");
	}
}
.StrikeOutButton {
	width: auto;
	height: auto;
	font-size: 1.5rem;
	font-weight: 500;
	// color: map-get($map: $blueGreyMap, $key: "400");
	color: map-get($map: $yellowMap, $key: "400");
	border: none;
	outline: none;
	background-color: transparent;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	&:hover {
		cursor: pointer;
		color: map-get($map: $yellowMap, $key: "700");
		text-decoration: line-through map-get($map: $yellowMap, $key: "700");
	}
}
.DeleteButton {
	width: auto;
	height: auto;
	font-size: 1.5rem;
	font-weight: 500;
	color: map-get($map: $redMap, $key: "400");
	border: none;
	outline: none;
	background-color: transparent;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	&:hover {
		cursor: pointer;
		color: map-get($map: $redMap, $key: "700");
	}
}
