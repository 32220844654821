@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ChangePasswordModal {
	position: fixed;
	top: 30%;
	left: 50%;
	width: 50rem;
	max-width: 100%;
	height: 55rem;
	margin-left: -25rem;
	margin-top: -27.5rem;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	z-index: 20;

	&_top {
		width: 100%;
		height: auto;
		padding: 0.5rem 1rem;
		@include flex_row(flex-end, center);

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "600");
		}
		&_close {
			width: 3rem;
			height: 3rem;
			border-radius: 0.5rem;
			@include flex_row(center, center);
			&:hover {
				background-color: map-get($map: $redMap, $key: "600");
				cursor: pointer;
			}
			&:hover &_icon {
				fill: #ffffff;
				cursor: pointer;
			}

			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
	}
	&_header {
		width: 100%;
		height: auto;
		padding: 0 2rem;
		@include flex_row(flex-start, center);
		margin-bottom: 3rem;

		&_iconWrapper {
			width: 7rem;
			height: 7rem;
			border-radius: 50%;
			@include flex_row(center, center);
			background-color: map-get($map: $greenMap, $key: "200");
			margin-right: 1rem;

			&_icon {
				width: 4.5rem;
				height: 4.5rem;
				fill: map-get($map: $purpleMap, $key: "600");
			}
		}

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
	&_main {
		width: 100%;
		height: auto;
		padding: 2rem;
	}
	&_indicators {
		width: 100%;
		height: 10rem;
		padding: 0 2rem;
	}
	&_actions {
		width: 100%;
		padding: 0 2rem;
		@include flex_row(flex-end, center);
	}
}

.Failure {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1rem;
	@include flex_col(flex-start, center);
	background-color: #ffffff;
	border-radius: 0.5rem;
	z-index: 20;

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, flex-start);
		margin-bottom: 6rem;

		&_closeIcon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "600");
			cursor: pointer;
		}
	}

	&_wrapper {
		width: 100%;
		height: auto;
		@include flex_row(center, center);

		&_icon {
			width: 8rem;
			height: 8rem;
			fill: map-get($map: $redMap, $key: "600");
		}
	}

	&_msg {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $redMap, $key: "600");
	}
}

@media only screen and (max-width: 50rem) {
	.ChangePasswordModal {
		width: 100vw;
		top: 10vh;
		left: 0;
		margin-left: 0;
		margin-top: -5vh;
	}
}
