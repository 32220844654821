@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ConfirmedIndicator {
	width: max-content;
	height: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $purpleMap, $key: "600");
		margin-right: 0.3rem;
	}
	&_msg {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "600");
	}
}
