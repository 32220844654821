@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DateRangeDaySM {
	width: 2.5rem;
	height: 2.5rem;
	padding: 0.2rem;
	border-radius: 50%;
	font-family: map-get($map: $fonts, $key: "tertiarySans");
	&:hover {
		border: 1px solid map-get($map: $flatColors, $key: "main");
		cursor: pointer;
	}

	&_entry {
		@include fill_container;
		@include flex_row(center, center);
		font-size: 1.2rem;
		font-weight: 400;
	}
	&_isToday {
		@extend .DateRangeDaySM;
		background-color: map-get($map: $flatColors, $key: "main");
		border-radius: 50%;
		color: #ffffff;
	}
	&_isSelected {
		@extend .DateRangeDaySM;
		border: 1px solid map-get($map: $colors, $key: "mainBlue");
		background-color: map-get($map: $flatColors, $key: "mainBlue");
	}
	&_inRange {
		@extend .DateRangeDaySM;
		background-color: map-get($map: $colors, $key: "mainGrey");
		opacity: 0.6;
	}
	&_isRestricted {
		@extend .DateRangeDaySM;
		background-color: map-get($map: $colors, $key: "mainGrey");
		opacity: 0.4;
		cursor: not-allowed;
	}
	&_isRestrictedAndToday {
		@extend .DateRangeDaySM;
		background-color: map-get($map: $flatColors, $key: "main");
		border-radius: 50%;
		color: #ffffff;
		opacity: 0.4;
		cursor: not-allowed;
	}
}
