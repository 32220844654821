@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FormSection {
	width: 100%;
	height: auto;
	padding: 1rem 1rem;
	border: none;
	outline: none;

	// main section titles
	&_heading {
		width: 100%;
		font-size: 1.8rem;
		font-weight: 800;
		color: map-get($map: $colors, $key: "mainBlackBlue");
		@include flex_row(flex-start, center);
		margin-bottom: 1rem;

		& > span > i {
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");
			margin-right: 0.5rem;
		}
	}
	// main grouping of elements
	&_content {
		width: 100%;
		height: max-content;
		padding: 0.5rem;
		padding: 0.5rem 2.5rem;

		&_request {
			padding: 0 1rem;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "main");
			margin-left: 1rem;
			margin-top: 0.5rem;
		}
	}

	&:disabled {
		opacity: 0.5;
	}
}

@media only screen and (max-width: 550px) {
	.FormSection {
		width: 100%;
		height: auto;
		padding: 1rem 0;
		border: none;
		outline: none;

		&_content {
			padding: 0.3rem 0;
		}
	}
}
