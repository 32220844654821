@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Dashboard {
	@include fill_container;
	padding: 2rem 1rem 2rem 1rem;

	&_feedbackWidget {
		width: 100%;
		height: auto;
	}
	&_content {
		@include fill_container;
	}
}

@media screen and (max-width: 600px) {
	.Dashboard {
		padding: 2rem 0.2rem;

		&_feedbackWidget {
			width: 100%;
		}
		&_content {
			@include fill_container;
		}
	}
}
