@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlShiftReportHeadings {
	width: 100%;
	height: 5rem;
	@include flex_row(flex-start, center);
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	color: #ffffff;

	&_left {
		width: 15rem;
		height: 100%;
		@include flex_row(center, center);
		border-right: 1px solid #eee;

		&_heading {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			color: #ffffff;
		}
	}
	&_center {
		width: auto;
		flex-grow: 1;
		@include flex_row(space-around, center);

		&_heading {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			color: #ffffff;
		}
	}
}
