@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VerifyUser {
	width: 100%;
	padding: 2rem 2rem;

	&_top {
		width: 100%;
		margin-bottom: 4rem;

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
		}

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}
