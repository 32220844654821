@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlScheduleSettings {
	@include fill_container;
	padding: 0 2rem;

	&_selector {
		width: 100%;
		@include flex_row(center, flex-start);
		padding-top: 4rem;
		margin-bottom: 2rem;
	}
}
