@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentInfo {
	@include fill_container;
	padding: 0.5rem;

	&_cell {
		width: auto;
		margin-bottom: 0.3rem;

		& > span {
			font-size: 1.3rem;
			& b {
				font-weight: 600;
			}
		}
	}
	&_title {
		margin-top: 1rem;
		font-size: 1.3rem;
		font-weight: 600;
		font-style: italic;
	}
	&_contacts {
		width: 90%;
		max-width: 90%;
		height: auto;
		@include flex_row(flex-start, center, wrap);

		&_entry {
			width: 20rem;
			height: auto;
			overflow-x: auto;
			margin: 0.5rem 1rem;
			padding: 0.5rem;
			border: 1px solid #eaecef;
			border-radius: 0.5rem;
			@include flex_col(flex-start, flex-start);

			& > span {
				font-size: 1.1rem;
				font-weight: 400;

				& > b {
					font-weight: 500;
					color: map-get($map: $blueGreys, $key: "headings");
				}
			}
		}
	}
	&_code {
		margin-top: 1rem;
		@include flex_col(flex-start, flex-start);
		color: map-get($map: $colors, $key: "mainRed");

		& > span {
			margin-bottom: 0.5rem;
			font-size: 1.3rem;
			font-weight: 600;

			& > b {
				font-size: 1.5rem;
				font-weight: 600;
			}
		}
	}
}

@media screen and (max-width: 660px) {
	.ResidentInfo {
		&_contacts {
			@include flex_col(flex-start, flex-start);
		}
	}
}
