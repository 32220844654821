@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

// GROUPED FILTERS
.FiltersPanelGroup {
	width: 100%;
	height: auto;
	padding: 0 1rem;
	margin-bottom: 1rem;

	&_top {
		width: 100%;

		&_title {
			display: block;
			font-size: 1.2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
			text-align: center;
			margin-bottom: 0.5rem;
		}
	}
	&_options {
		width: 100%;
		height: auto;
		display: grid;
		grid-template-columns: repeat(3, auto);
		place-items: center center;

		&_option {
			@include fill_container;
			margin-bottom: 0.3rem;
		}
	}
}

// ACTUAL PANEL UI
.FiltersPanel {
	position: absolute;
	top: 1rem;
	right: 2rem;
	width: 35rem;
	height: 22rem;
	overflow-y: auto;
	padding: 0.3rem 0.5rem;
	border-radius: 0.5rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "raised");
	z-index: -1;

	&_title {
		font-size: 1.2rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
		text-align: center;
	}

	&_inner {
		@include fill_container;
		padding: 0.5rem 1rem;

		&_selections {
			width: max-content;
			margin-left: 1rem;
			margin-bottom: 2rem;

			&_clearAll {
				padding: 0.3rem 0.5rem;
				border-radius: 5rem;
				background-color: map-get($map: $colors, $key: "mainRed");
				border: none;
				outline: none;
				color: #ffffff;
				margin-left: 0.5rem;
				&:hover {
					cursor: pointer;
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
				}
			}
			&_selectAll {
				padding: 0.3rem 0.5rem;
				border-radius: 5rem;
				background-color: map-get($map: $colors, $key: "main");
				border: none;
				outline: none;
				color: #ffffff;
				&:hover {
					cursor: pointer;
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
				}
			}
		}
	}
}
