@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportsCard {
	width: 40rem;
	height: 30rem;
	padding: 2rem;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
	outline: none;
	background-color: #ffffff;
	color: map-get($map: $colors, $key: "mainBlackBlue");
	margin-bottom: 2rem;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;

	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "heavySubtle");
		color: map-get($map: $colors, $key: "main");
	}
	&_report {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
		margin-bottom: -0.8rem;

		&_name {
			font-size: 1.4rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_cardIcon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $colors, $key: "mainBlackBlue");
			margin-right: 0.3rem;
		}
	}

	&_content {
		width: 100%;
		height: auto;
		padding: 1rem 2.3rem;
	}

	&_preview {
		width: 100%;
		height: 20rem;
		-o-object-fit: fill;
		object-fit: fill;
		-o-object-position: center;
		object-position: center;
		@include flex_col(center, center);

		&:hover {
			cursor: pointer;
		}
		&:hover .ReportsCard {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "heavySubtle");
			color: map-get($map: $colors, $key: "main");
		}

		&_img {
			@include fill_container;
			border-radius: 0.5rem;
		}
		&_fallbackIcon {
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $colors, $key: "altYellow");
			margin-bottom: 1rem;
		}
		&_fallbackTitle {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
	}
	&:hover &_report_name,
	&:hover &_report_cardIcon {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.4;
		&:hover {
			cursor: not-allowed;
		}
	}
	&:disabled &_preview,
	&:disabled &_content,
	&:disabled &_report {
		&:hover {
			cursor: not-allowed;
		}
	}
}

@media screen and (max-width: 500px) {
	.ReportsCard {
		width: 40rem;
		height: 30rem;
	}
}
