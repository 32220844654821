@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlShiftReportPanel {
	@include fill_container;

	&_section {
		width: 100%;
		height: auto;
		padding: 0 1rem;
		margin-bottom: 2rem;
		&_title {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "headings");
		}
		&_warning {
			margin-top: 1rem;
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainRed");
		}
	}
}
