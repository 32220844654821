@import "../../sass/variables";
@import "../../sass/mixins";

.PastDueReport {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 2rem;

		&_title {
			font-size: 2.8rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	&_selector {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;
	}
}
