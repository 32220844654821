@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

// single shift entry
.CommunityShift {
	position: relative;
	width: auto;
	height: 5rem;
	padding: 1rem;
	@include flex_col(center, center);
	background-color: #ffffff;
	border-radius: 0.5rem;
	border: 1px solid #eaecef;
	opacity: 0.6;
	font-family: "Open Sans", sans-serif;

	&_name {
		font-size: 1.2rem;
		font-weight: 700;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
	&_times {
		margin-top: 0.5rem;
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}

	&_isCurrent {
		@extend .CommunityShift;
		background-color: #ffffff;
		opacity: 1;

		&_name {
			font-size: 1.2rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_times {
			margin-top: 0.5rem;
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "main");
			font-family: "Open Sans", sans-serif;
		}
		&_isBeforeStart {
			position: absolute;
			top: -1.1rem;
			right: 30%;
			font-size: 0.95rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}
}

.CommunityShifts {
	width: 100%;
	height: auto;
	margin-bottom: 2rem;

	&_section {
		@include fill_container;
		@include flex_row(center, flex-start);
	}
}

@media only screen and (max-width: 450px) {
	.CommunityShift {
		padding: 0.3rem;
		&_name {
			font-size: 1rem;
		}
		&_times {
			font-size: 0.8rem;
		}
	}
}
