@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentTableBody {
	@include fill_container;
	max-height: 30rem;
	padding: 2rem 0.5rem;
	position: relative;
	z-index: 1;
}
