@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/animations";
@import "../../sass/custom";

$width: 98vw;
$height: 90vh;

.ModalFull {
	max-width: $width;
	max-height: $height;
	width: $width;
	height: $height;
	@include add_center_focus($width, $height);
	background-color: #ffffff;
	border-radius: 1rem;
	@include slide_in_center;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	z-index: 100;

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 2rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $colors, $key: "mainRed");
			cursor: pointer;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				background-color: map-get($map: $greys, $key: "chalk");
				border-radius: 0.5rem;
				-webkit-transform: rotateZ(360deg);
				transform: rotateZ(360deg);
			}
		}
	}
	&_main {
		@include fill_container;
		height: 80vh;
		padding: 2rem 2rem;
		padding-bottom: 4rem;
		overflow-y: scroll;
	}
}

@media screen and (max-width: 450px) {
	.ModalFull {
		max-width: 96vw;
		max-height: 88vh;
		margin-left: -48vw;
		margin-top: -44vh;
		@include add_center_focus(96vw, 88vh);
		background-color: #ffffff;
		border-radius: 1rem;
		box-shadow: map-get($map: $shadows, $key: "focusMode");
		z-index: 100;
		z-index: 100;

		&_main {
			padding: 2rem 0.5rem;
		}
	}
}
