@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportPane {
	width: auto;
	min-height: 5rem;
	height: auto;
	min-height: auto;
	max-height: 95rem;
	overflow-x: auto;
	border: 1px solid map-get($map: $colors, $key: "mainGrey");
	border-radius: 0.5rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");
	margin-bottom: 2rem;
	background-color: #ffffff;
	// -webkit-transition: height 0.3s ease-in-out;
	// transition: height 0.3s ease-in-out;

	/* Background tracking */
	&::-webkit-scroll-track {
		background-color: #ffffff;
	}
	/* Sets width/height of 'thumb' & hides default */
	&::-webkit-scrollbar {
		appearance: none;
		-webkit-appearance: none;
		width: 1rem;
		height: 1rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "400");
		border-radius: 5rem;
	}

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem;
		padding-bottom: 3rem;
		@include flex_row(flex-start, flex-start);

		&_toggle {
			width: -webkit-max-content;
			width: -moz-max-content;
			width: max-content;
			height: -webkit-max-content;
			height: -moz-max-content;
			height: max-content;
			@include flex_row(flex-start, flex-start);
			&:hover {
				cursor: pointer;
			}
			&:hover &_title {
				color: map-get($map: $colors, $key: "main");
				cursor: pointer;
			}
			&_title {
				font-size: 1.8rem;
				color: map-get($map: $blueGreys, $key: "headings");
			}

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $blueGreys, $key: "headings");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
		}

		&_icons {
			position: relative;
			width: auto;
			margin-left: auto;
			&_icon {
				width: 1.7rem;
				height: 1.7rem;
				fill: map-get($map: $blueGreys, $key: "headings");
				margin-left: 0.5rem;
				z-index: 2;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
			&_menu {
				position: absolute;
				top: 0;
				right: 0;
				width: 20rem;
				height: 20rem;
				padding: 0.5rem;
				border-radius: 0.5rem;
				background-color: #ffffff;
				box-shadow: map-get($map: $shadows, $key: "raised");
				@include flex_col(flex-start, flex-start);
				z-index: 60;

				&_list {
					width: 100%;
					list-style: none;
					padding: 0.5rem 0.5rem;

					&_item {
						width: 100%;
						font-size: 1.2rem;
						padding: 0.8rem 0.3rem;
						border-radius: 0.5rem;
						margin-bottom: 1rem;
						color: map-get($map: $colors, $key: "main");
						@include flex_row(flex-start, center);

						&:hover {
							cursor: pointer;
							background-color: map-get($map: $greys, $key: "chalk");
						}
						& > b {
							font-size: 1.8rem;
							font-weight: 300;
						}
					}
				}
			}
		}
	}
	&_dataViz {
		position: relative;
		width: 100%;
		min-width: 120rem;
		height: auto;
		max-height: 100%;
		// height: 90rem;
		overflow-y: auto;

		z-index: 2;
		@include scroll_gradient;
	}
	&_isCollapsed {
		@extend .ReportPane;
		height: 8rem;
	}
}

.refWrapper {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.closeIcon {
	width: 1.4rem;
	height: 1.4rem;
	fill: map-get($map: $colors, $key: "mainRed");
	align-self: flex-end;
	cursor: pointer;
}

@media screen and (max-width: 800px) {
	.ReportPane {
		min-width: 100%;
		height: auto;

		&_top {
			&_title {
				font-size: 1.6rem;
			}
		}

		&_dataViz {
			overflow-x: auto;
		}
	}
}

.loadingMsg {
	font-size: 1.5rem;
	font-weight: 700;
	display: flex;
	justify-content: center;
	color: map-get($map: $blueGreys, $key: "headings");
}
