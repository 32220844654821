@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RecentReassess {
	width: 100%;
	height: max-content;
	margin: 2rem 0;
	padding-left: 2.5rem;

	&_viewHistory {
		width: 100%;
		height: auto;

		&_btn {
			color: map-get($map: $colors, $key: "main");
			font-size: 1.4rem;
			font-weight: 600;
			border: none;
			outline: none;
			background-color: transparent;
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
				text-decoration: underline;
			}
		}
	}

	&_entry {
		@include fill_container;
		max-height: 15rem;
		padding: 1rem;
		overflow-y: auto;
	}
}

.ReassessEntry {
	@include fill_container;
	height: auto;
	padding: 0.5rem 1rem;
	list-style-type: "\2713";

	&_notes {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "headings");
	}
	&_timestamp {
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "headings");
		padding-left: 1.5rem;
	}
}
