@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ShiftHeadings {
	width: 100%;
	height: 12rem;
	@include flex_row(flex-start, flex-start);

	&_adl {
		width: 10rem;
		height: 100%;
		font-size: 2rem;
		font-weight: 800;
		color: map-get($map: $colors, $key: "mainBlackBlue");
		border: 1px solid #eaecef;
		border-right: none;
		@include flex_row(center, center);
	}
}
