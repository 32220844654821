@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportViewerTableActionsPanel {
	width: 100%;
	min-height: 2rem;
	height: auto;
	margin-bottom: 2rem;

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 1rem;
		@include flex_row(center, start);
		margin-bottom: 2rem;

		&_title {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
		}

		&_settings {
			width: max-content;
			height: max-content;
			margin-left: auto;

			&_btn {
				background-color: transparent;
				border: none;
				outline: none;
				color: map-get($map: $colors, $key: "main");
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "main");
				}
			}
		}
	}

	&_inner {
		width: 100%;
		@include flex_row(center, center);

		&_search {
			@include flex_row(center, center);
			width: -webkit-max-content;
			width: -moz-max-content;
			width: max-content;
			height: -webkit-max-content;
			height: -moz-max-content;
			height: max-content;
			background-color: #eaecef;
			border-radius: 5rem;
			padding: 0.3rem 0.5rem;

			&:focus-within,
			&:hover {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}

			&_icon {
				width: 2rem;
				height: 2rem;
				background-color: #eaecef;
				fill: map-get($map: $blueGreys, $key: "headings");
			}
			&_input {
				width: 25rem;
				height: 3rem;
				border: none;
				outline: none;
				border-radius: 5rem;
				font-size: 1.6rem;
				font-weight: 500;
				padding-left: 0.3rem;
				background-color: #eaecef;

				&::placeholder {
					font-size: 1.6rem;
					font-weight: 400;
				}
			}
		}
	}
}
