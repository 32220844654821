@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FeedbackSuccess {
	@include fill_container;
	@include flex_col(center, center);

	&_icon {
		margin-top: -0.5rem;
		width: 4rem;
		height: 4rem;
		fill: map-get($map: $greenMap, $key: "400");
	}
	&_msg {
		font-size: 1.8rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "800");
		text-align: center;
	}
	&_submitMoreBtn {
		margin-top: 1rem;
		padding: 0.4rem 1rem;
		border-radius: 5rem;
		background-color: map-get($map: $blueGreyMap, $key: "200");
		color: map-get($map: $blueGreyMap, $key: "600");
		border: none;
		outline: none;
		&:hover {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
			cursor: pointer;
		}
	}
}
