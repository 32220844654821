@import "../../sass/variables";
@import "../../sass/custom";
@import "../../sass/mixins";
@import "../../sass/animations";

.DailySummaryCard {
	width: 40rem;
	height: max-content;
	padding: 0 1rem;
	background-color: #ffffff;
	border-radius: 0.5rem;
	border-top: 3px solid map-get($map: $colors, $key: "main");
	position: relative;
	margin: 0 0.2rem;
	margin-bottom: 2rem;

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 0.5rem;
		@include flex_row(flex-start, center);
		margin-bottom: 1rem;

		&_completedBadge {
			position: absolute;
			top: 10%;
			left: 35%;
			width: max-content;
			height: max-content;
			padding: 0.5rem 0.8rem;
			font-size: 1.3rem;
			border-radius: 5rem;
			background-color: map-get($map: $flatColors, $key: "mainGreen");
			opacity: 1;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			border: 1px solid map-get($map: $colors, $key: "mainGreen");
			z-index: 0;
		}

		&_date {
			width: auto;

			&_title,
			&_adl {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
			}

			&_adl {
				font-weight: 700;
			}

			&_title {
				font-size: 1rem;
			}
		}

		&_notes {
			margin-left: auto;
			align-self: flex-start;
			color: map-get($map: $colors, $key: "main");
			font-weight: 700;
			font-family: map-get($map: $fonts, $key: "mainSans");
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}

		&_icon {
			position: relative;
			margin-left: auto;
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");

			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "main");
			}
		}
	}

	&_inner {
		width: 100%;
		height: auto;
		padding: 1rem;
		@include flex_row(space-evenly, flex-start);

		&_tile {
			width: 20%;
			height: auto;
			text-align: center;

			&_heading {
				color: map-get($map: $blueGreys, $key: "subheadings");

				& > b {
					color: map-get($map: $colors, $key: "mainBlackBlue");
					font-size: 1rem;
					font-weight: 700;
				}
				&_red {
					color: map-get($map: $colors, $key: "mainRed");
				}
				&_purple {
					color: map-get($map: $colors, $key: "main");
				}
				&_green {
					color: map-get($map: $colors, $key: "mainGreen");
				}
				&_yellow {
					color: map-get($map: $colors, $key: "altYellow");
				}
			}

			&_val {
				font-size: 1.5rem;
				color: map-get($map: $blueGreys, $key: "headings");

				&_red {
					@extend .DailySummaryCard_inner_tile_val;
					color: map-get($map: $colors, $key: "mainRed");
				}

				&_green {
					@extend .DailySummaryCard_inner_tile_val;
					color: map-get($map: $colors, $key: "mainGreen");
				}

				&_purple {
					@extend .DailySummaryCard_inner_tile_val;
					color: map-get($map: $colors, $key: "main");
				}

				&_blue {
					@extend .DailySummaryCard_inner_tile_val;
					color: map-get($map: $colors, $key: "mainBlue");
				}
				&_altYellow {
					@extend .DailySummaryCard_inner_tile_val;
					color: map-get($map: $colors, $key: "altYellow");
				}
			}
		}
	}

	&_tasks {
		width: 100%;
		height: 10rem;
		background-color: rgba(234, 236, 239, 0.3);
		border-radius: 0.5rem;
	}

	&_bottom {
		width: 100%;
		height: 100%;
		padding: 2rem 1rem 1rem 1rem;
		@include flex_row(flex-start, flex-end); // maybe not needed???

		&_complete {
			width: max-content;
			height: 100%;
		}
	}

	&_isCompleted {
		@extend .DailySummaryCard;
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
}

@media only screen and (max-device: 500px) {
	.DailySummaryCard {
		width: 40rem;
	}
}

@media only screen and (max-width: 400px) {
	.DailySummaryCard {
		max-width: 39rem;
		&_inner {
			&_tile {
				width: auto;
			}
		}
	}
}
