@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentSvcPlanTable {
	width: 100%;
	height: auto;
	min-height: 40rem;
	border-radius: 0.5rem 0.5rem 0 0;
	border: 1px solid #ccc;

	&_header {
		width: 100%;
		height: 6rem;
		@include flex_row(space-evenly, center);
		background-color: map-get($map: $blueGreyMap, $key: "800");
		border-radius: 0.5rem 0.5rem 0 0;

		&_column {
			width: 33.33%;
			height: 6rem;
			@include flex_row(center, center);
			border-right: 1px solid #ccc;

			&_label {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "300");
			}
		}
	}
}
