@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AllCompletedBanner {
	width: max-content;
	height: max-content;
	@include flex_col(center, center);
	padding: 1rem 2.5rem;
	border-radius: 5rem;
	background-color: map-get($map: $greenMap, $key: "300");
	box-shadow: map-get($map: $shadows, $key: "pronounced");
	margin-bottom: 6rem;

	&_top {
		@include fill_container;
		@include flex_row(center, center);

		&_icon {
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $colors, $key: "main");
			margin-right: 0.3rem;
		}

		&_title {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "main");
		}
	}
	&_count {
		@include fill_container;
		@include flex_row(center, center);

		&_text {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
			& > b {
				color: map-get($map: $purpleMap, $key: "700");
			}
		}
	}
	&_details {
		@include fill_container;
		min-height: 3rem;
		@include flex_row(center, center);

		&_btn {
			border: none;
			outline: none;
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
			}
		}
	}
}
