@import "../../sass/variables";
@import "../../sass/mixins";

.DailySummaryList {
	@include fill_container;
	height: 12rem;
	padding: 0.1rem 0 2rem 0;
	overflow-y: auto;
	list-style: none;
	background-color: rgba(234, 236, 239, 0.3);
	// -webkit-overflow-scrolling: touch; // fixes 'momentum' scroll on iOS/mobile
	-webkit-overflow-scrolling: auto;
	/* Background tracking */
	&::-webkit-scroll-track {
		background-color: #ffffff;
	}
	/* Sets width/height of 'thumb' & hides default */
	&::-webkit-scrollbar {
		appearance: none;
		-webkit-appearance: none;
		width: 0.7rem;
		border-radius: 5rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "300");
		border-radius: 5rem;
	}
}

ul[class^="DailySummaryList_DailySummaryList"]::-webkit-scrollbar {
	appearance: none;
	-webkit-appearance: none;
	width: 0.7rem;
	border-radius: 5rem;
}
ul[class^="DailySummaryList_DailySummaryList"]::-webkit-scrollbar-thumb {
	background-color: map-get($map: $blueGreyMap, $key: "300");
	border-radius: 5rem;
}
