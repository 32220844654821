@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.MedsList {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-height: 10rem;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	overflow-y: auto;

	&_list {
		list-style-type: none;

		&_item {
			font-size: 1.2rem;
			font-weight: 300;
			@include flex_col(flex-start, flex-start);

			&_wrapper {
				& > b {
					color: map-get($map: $blueGreys, $key: "subheadings");
				}
				& > i {
					color: map-get($map: $colors, $key: "mainRed");
					font-weight: 500;
				}
			}
		}
	}

	&_EMPTY {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $colors, $key: "mainRed");
	}
}
