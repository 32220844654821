@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditShiftForm {
	width: 100%;
	height: auto;

	&_section {
		width: max-content;
		height: max-content;
		margin-bottom: 2rem;

		&_current {
			font-size: 1.5rem;
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "600");
			margin-bottom: 4rem;

			& b {
				color: map-get($map: $redMap, $key: "600");
			}
		}

		&_heading {
			width: 100%;
			height: auto;
			@include flex_row(flex-start, center);

			&_title {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainBlackBlue");

				& > b {
					color: map-get($map: $colors, $key: "main");
				}
			}
			&_icon {
				margin-right: 0.5rem;
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
			}
		}
	}
	&_content {
		width: 100%;
		height: max-content;
		padding: 0 2rem;
		margin-bottom: 2rem;
		text-align: left;

		&_warning {
			width: 100%;
			height: auto;
			margin-bottom: 3rem;
			@include flex_row(flex-start, center);

			&_icon {
				margin-right: 0.5rem;
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $colors, $key: "mainRed");
				align-self: flex-start;
			}
			&_notice {
				width: max-content;
				height: auto;
				@include flex_col(flex-start, flex-start);

				&_title {
					font-size: 1.6rem;
					font-weight: 700;
					color: map-get($map: $colors, $key: "mainRed");
				}
				&_msg {
					font-size: 1.3rem;
					font-weight: 500;
					color: map-get($map: $colors, $key: "mainRed");
				}
			}
		}

		&_descriptions {
			width: auto;
			@include flex_col(flex-start, flex-start);
			margin-bottom: 4rem;

			&_desc {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "subheadings");

				& > b {
					color: map-get($map: $colors, $key: "main");
				}
			}
		}
	}
	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
