@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.WidgetTrigger {
	width: max-content;
	height: max-content;
	position: relative;
	display: block;

	&_btn {
		text-align: center;
		border: none;
		outline: none;
		background-color: transparent;
		color: map-get($map: $purpleMap, $key: "700");
		font-size: 1.6rem;
		font-weight: 600;
		&:hover {
			cursor: pointer;
			color: map-get($map: $purpleMap, $key: "500");
		}
	}
}
