@import "./sass/variables";
@import "./sass/mixins";
@import "./sass/custom";

.App {
	max-width: 100vw;
	height: 100vh;
}

.title {
	font-size: 3rem;
	font-weight: 800;
	color: map-get($map: $colors, $key: "mainBlackBlue");
	padding-top: 4rem;
}

.subtitle {
	font-size: 1.8rem;
	font-weight: 300;
	margin-bottom: 2rem;
}

.icon {
	width: 1.7rem;
	height: 1.7rem;
	fill: map-get($map: $blueGreys, $key: "headings");

	&:hover {
		cursor: pointer;
		fill: map-get($map: $colors, $key: "main");
	}
}

.divider {
	opacity: 0.3;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

// VIEW GLOBAL STYLES
.viewTitle {
	font-size: 3rem;
	font-weight: 700;
	color: map-get($map: $colors, $key: "mainBlackBlue");
	text-align: center;
	margin-left: 2rem;
}
