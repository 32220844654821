@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ADLShiftDetails {
	@include fill_container;
	padding: 2rem 1rem;

	&_residentInfo {
		width: 100%;
		height: auto;
		padding: 0.5rem;
		@include flex_row(flex-start, flex-start, wrap);

		&_photo {
			width: max-content;
			height: max-content;
			margin-right: 2rem;
		}
		&_name {
			@include flex_col(flex-start, flex-start);
			width: max-content;
			height: max-content;
			padding: 2rem 1rem;

			&_title {
				font-size: 1.8rem;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-bottom: 0.5rem;
			}
			&_subtitle {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "subheadings");
				margin-left: 0.5rem;
				margin-bottom: 0.2rem;
			}
		}
	}

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_date {
			font-size: 1.2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
			padding-left: 0.3rem;
		}
		&_hasException {
			width: max-content;
			margin-right: auto;
			margin-left: 0.5rem;
			padding: 0.3rem 0.8rem;
			border-radius: 5rem;
			background-color: map-get($map: $flatColors, $key: "mainRed");
			@include flex_row(flex-start, flex-start);

			&_text {
				font-size: 1.7rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainRed");
				margin-right: 0.5rem;
			}

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
	}

	&_empty {
		width: 100%;

		&_msg {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	// selected task - details
	&_details {
		width: 100%;
		height: auto;
		padding-bottom: 4rem;

		&_heading {
			width: 100%;
			@include flex_row(flex-start, center);

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $blueGreys, $key: "subheadings");
				margin-right: 0.5rem;
			}

			&_title {
				font-size: 1.6rem;
				font-weight: 700;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
			&_subtitle {
				font-size: 1.3rem;
				font-weight: 500;
			}
			&_taskIndex {
				font-size: 2rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "main");
				& > b {
					color: map-get($map: $blueGreys, $key: "subheadings");
				}
			}
		}
		&_section {
			width: 100%;
			padding: 1rem 2.5rem;
			padding-left: 3rem;

			&_item {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "headings");

				&_status {
					width: auto;
					@include flex_row(flex-start, center);
					font-size: 1.4rem;
					font-weight: 600;
					color: map-get($map: $blueGreys, $key: "headings");

					&_badge {
						margin-left: 0.5rem;
						width: max-content;
						height: max-content;
						padding: 0.4rem 1rem;
						border-radius: 5rem;
						border: 1px solid map-get($map: $colors, $key: "mainBlackBlue");
						box-shadow: map-get($map: $shadows, $key: "medSubtle");
					}
				}
				&_notes {
					width: 100%;
					height: 30rem;
					padding: 1rem;
					border-radius: 0.5rem;
					border: none;
					outline: none;
					resize: none;
					background-color: #eaecef;
					font-family: map-get($map: $fonts, $key: "mainSans");

					&::placeholder {
						color: map-get($map: $colors, $key: "mainBlackBlue");
						font-size: 1.4rem;
						font-weight: 500;
					}
				}
				&_exception {
					color: map-get($map: $colors, $key: "mainRed");
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.ADLShiftDetails {
		&_top {
			@include flex_row(space-between, center, wrap);
		}

		&_section {
			padding: 1rem 1.5rem;
			padding-left: 1.8rem;
		}
	}
}
