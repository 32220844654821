@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentRow {
	width: 100%;
	height: 5rem;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	border-left: 3px solid #eaecef;
	@include flex_row(space-between, center);

	&:hover {
		box-shadow: map-get($map: $shadows, $key: "light");
	}

	&:first-of-type {
		border-top: 1px solid #eaecef;
	}

	&:nth-child(even) {
		border-top: 1px solid #eaecef;
		border-bottom: 1px solid #eaecef;
	}

	&_cellSelection {
		width: 5%;
		height: 100%;
		padding: 0.5rem;
		margin-top: 0.7rem;
		margin-right: auto;
	}

	&_cell {
		width: 30%;
		height: 100%;
		padding: 0.5rem;
		@include flex_row(center, center);

		&:last-of-type {
			margin-left: auto;
		}

		&_label {
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");

			&:last-of-type {
				margin-left: auto;
			}
		}
	}

	&_isSelected {
		@extend .ResidentRow;
		border-left: 3px solid map-get($map: $colors, $key: "main");
		background-color: #eaecef;
	}
}
