@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportViewerNotesCell {
	width: 100%;
	height: auto;
	// @include fill_container;
	@include flex_col(flex-start, center);
	// display: block;
	// margin: auto auto;
	// word-break: break-all;
	overflow-y: scroll;
	// overflow-y: scroll;
}

.NotesEntry {
	width: 100%;
	height: auto;
	// height: 100%;
	text-align: center;
	font-size: 1.4rem;
	font-weight: 500;
	font-family: map-get($map: $fonts, $key: "altSans");
	margin: 0.5rem 0;
}
