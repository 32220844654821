@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DateRangeCalendarSM {
	position: absolute;
	top: 4rem;
	left: 0;
	width: 25rem;
	max-height: 35rem;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	padding: 1rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");

	&_focusMode {
		@extend .DateRangeCalendarSM;
		@include focusMode;
		margin-left: -12.5rem;
		margin-top: -17.5rem;
		z-index: 50;
	}

	&_controls {
		width: 100%;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		padding: 0.5rem 0.3rem;
		@include flex_row(space-between, center);
		margin-bottom: 1.8rem;

		&_heading {
			font-size: 1.8rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "mainBlackBlue");

			& > b {
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
		&_arrows {
			width: -webkit-max-content;
			width: -moz-max-content;
			width: max-content;
			height: 100%;
			@include flex_row(space-evenly, center);

			&_chevron {
				width: 2rem;
				height: 2rem;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
				&:nth-child(1) {
					margin-right: 0.5rem;
				}
			}
		}
	}
	&_weekDays {
		width: 100%;
		display: grid;
		grid-template-columns: repeat((auto-fit, calc(100% / 7)));
		justify-items: center;
		margin-bottom: 1rem;
		& > span {
			font-size: 1.2rem;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
	}

	&_calendar {
		width: 100%;
		height: auto;
		@include calendar;
		grid-template-rows: repeat(auto-fit, auto);
		justify-items: center;
		grid-gap: 1rem 0;
		margin-bottom: 2rem;
		-webkit-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
	}

	&_today {
		width: 100%;
		height: 4rem;
		padding: 1rem 0;
		@include flex_row(center, center);

		&_btn {
			border: none;
			outline: none;
			font-size: 1.2rem;
			font-weight: 600;
			letter-spacing: 0.2rem;
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");

			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}
	}
}
