@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DashboardNav {
	display: block;
	margin: 0 auto;
	width: 100%;
	height: auto;
	padding: 1rem 2rem;
	padding-top: 8rem;
	background-color: transparent;
	@include flex_row(space-between, flex-start);

	&_backBtn {
		position: relative;
		background-color: #fff;
		width: 4rem;
		height: 4rem;
		border: none;
		outline: none;
		border-radius: 50%;
		box-shadow: map-get($map: $shadows, $key: "purpleRain");
		@include flex_row(center, center);

		&_backLabel {
			position: absolute;
			top: 4.1rem;
			left: -0.2rem;
			font-size: 1rem;
			font-weight: 600;
		}

		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $colors, $key: "main");

			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainBlue");
			}
		}

		&:focus {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&:hover {
			cursor: pointer;
			border: 1px solid map-get($map: $colors, $key: "main");
			background-color: map-get($map: $colors, $key: "main");
		}
		&:hover &_icon {
			fill: #ffffff;
		}
	}
	&_forwardBtn {
		position: relative;
		background-color: #fff;
		width: 4rem;
		height: 4rem;
		border: none;
		outline: none;
		border-radius: 50%;
		margin-left: auto;
		box-shadow: map-get($map: $shadows, $key: "purpleRain");
		@include flex_row(center, center);

		&_forwardLabel {
			position: absolute;
			top: 3rem;
			left: -0.4rem;
			font-size: 1rem;
			font-weight: 600;
		}

		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $colors, $key: "main");

			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainBlue");
			}
		}

		&:focus {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&:hover {
			cursor: pointer;
			border: 1px solid map-get($map: $colors, $key: "main");
			background-color: map-get($map: $colors, $key: "main");
		}
		&:hover &_icon {
			fill: #ffffff;
		}
	}
}

@media only screen and (max-width: 550px) {
	.DashboardNav {
		&_backBtn,
		&_forwardBtn {
			width: 2.7rem;
			height: 2.7rem;

			&_icon {
				width: 1.8rem;
				height: 1.8rem;
			}
			&_backLabel,
			&_forwardLabel {
				position: absolute;
				top: 3rem;
				left: -0.4rem;
				font-size: 1rem;
				font-weight: 600;
			}
		}
	}
}
