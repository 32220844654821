@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ValidationLoader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #ffffff;
	opacity: 0.8;

	&_inner {
		width: 100%;
		height: 100%;
		@include flex_col(center, center);
		opacity: 1;

		&_message {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
}
