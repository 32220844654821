@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RecentlyViewedReports {
	@include fill_container;
	@include flex_row(flex-start, center, wrap);

	&_heading {
		width: max-content;

		&_title {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
			margin-bottom: 2rem;
		}
	}

	&_empty {
		width: 100%;
		height: auto;

		&_msg {
			font-size: 1.3rem;
			margin-bottom: 2rem;
			color: map-get($map: $colors, $key: "altRed");
		}

		&_btn {
			padding: 0.8rem 1.2rem;
			border-radius: 5rem;
			background-color: map-get($map: $colors, $key: "main");
			color: #ffffff;
			font-size: 1.5rem;
			font-weight: 600;
			border: none;
			outline: none;
			@include flex_row(flex-start, center);
			box-shadow: map-get($map: $shadows, $key: "subtle");
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "heavySubtle");
			}

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: #ffffff;
			}
		}
	}
}
