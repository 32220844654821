@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportSettings {
	@include fill_container;

	&_panelHeading {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-bottom: 2rem;
	}
	&_desc {
		width: auto;
		margin-bottom: 3rem;

		&_text {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	&_current {
		width: 100%;

		&_heading {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
			margin-bottom: 0.5rem;
			text-decoration: underline;
		}

		&_details {
			width: 100%;
			height: auto;
			padding: 0 2rem;
			@include flex_col(flex-start, flex-start);

			&_item {
				font-size: 1.2rem;
				font-weight: 500;
				margin-bottom: 0.5rem;
				color: map-get($map: $blueGreys, $key: "headings");
				&:nth-child(3),
				&:last-child {
					margin-left: 2rem;
				}
			}
		}
	}

	&_actions {
		width: 100%;
		height: 100%;
		padding: 1rem 2rem;
		@include flex_row(flex-end, flex-end);

		&_btn {
			padding: 0.8rem 1.2rem;
			border-radius: 5rem;
			background-color: map-get($map: $colors, $key: "main");
			color: #ffffff;
			font-size: 1.5rem;
			font-weight: 600;
			border: none;
			outline: none;
			@include flex_row(flex-start, center);
			box-shadow: map-get($map: $shadows, $key: "subtle");
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "heavySubtle");
			}

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: #ffffff;
			}
		}
	}
}
