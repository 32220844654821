@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ADLHeadings {
	width: calc(100% / 8);
	// height: auto;
	height: 10rem;
	border-left: 1px solid #eaecef;
	border-right: 1px solid #eaecef;
	@include flex_col(flex-start, flex-start);
	flex-basis: 1;

	&_adl {
		@include flex_col(center, center);
		@include fill_container;
		min-height: 10rem;
		padding: 0.5rem;
		border-right: 1px solid #eaecef;
		border-radius: 0.5rem 0 0 0.5rem;

		&_wrapper {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			@include flex_row(center, center);
			border: 1px solid map-get($map: $purpleMap, $key: "600");
			background-color: map-get($map: $blueGreyMap, $key: "200");
			margin-bottom: 0.3rem;
			box-shadow: map-get($map: $shadows, $key: "subtle");
			&_icon {
				width: 2.7rem;
				height: 2.7rem;
				fill: map-get($map: $purpleMap, $key: "700");
			}
		}

		& > span {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "700");
		}

		&:nth-child(even) {
			border-top: 1px solid #eaecef;
			border-bottom: 1px solid #eaecef;
		}

		// &:nth-child(1) {
		// 	border-left: 3px solid hsla(197, 100%, 50%, 1);
		// 	border-bottom: 3px solid hsla(197, 100%, 50%, 1);
		// }

		// &:nth-child(2) {
		// 	border-left: 3px solid hsla(170, 100%, 39%, 1);
		// 	border-bottom: 3px solid hsla(170, 100%, 39%, 1);
		// }

		// &:nth-child(3) {
		// 	border-left: 3px solid hsla(60, 92%, 71%, 1);
		// 	border-bottom: 3px solid hsla(60, 92%, 71%, 1);
		// }

		// &:nth-child(4) {
		// 	border-left: 3px solid hsla(352, 70%, 50%, 1);
		// 	border-bottom: 3px solid hsla(352, 70%, 50%, 1);
		// }

		// &:nth-child(5) {
		// 	border-left: 3px solid hsla(220, 18%, 20%, 1);
		// 	border-bottom: 3px solid hsla(220, 18%, 20%, 1);
		// }

		// &:nth-child(6) {
		// 	border-left: 3px solid hsla(46, 100%, 50%, 1);
		// 	border-bottom: 3px solid hsla(46, 100%, 50%, 1);
		// }

		// &:nth-child(7) {
		// 	border-left: 3px solid hsla(292, 65%, 68%, 1);
		// 	border-bottom: 3px solid hsla(292, 65%, 68%, 1);
		// }

		// &:nth-child(8) {
		// 	border-left: 3px solid hsla(242, 89%, 64%, 1);
		// 	border-bottom: 3px solid hsla(242, 89%, 64%, 1);
		// }

		// &:nth-child(9) {
		// 	border-left: 3px solid hsla(186, 100%, 50%, 1);
		// 	border-bottom: 3px solid hsla(186, 100%, 50%, 1);
		// }

		// &:nth-child(10) {
		// 	border-left: 3px solid hsla(151, 100%, 45%, 1);
		// 	border-bottom: 3px solid hsla(151, 100%, 45%, 1);
		// }

		// &:nth-child(11) {
		// 	border-left: 3px solid hsla(330, 100%, 41%, 0.22);
		// 	border-bottom: 3px solid hsla(330, 100%, 41%, 0.22);
		// }

		// &:nth-child(12) {
		// 	border-left: 3px solid hsla(332, 100%, 74%, 1);
		// 	border-bottom: 3px solid hsla(332, 100%, 74%, 1);
		// }

		// &:nth-child(13) {
		// 	border-left: 3px solid hsla(259, 77%, 64%, 0.4);
		// 	border-bottom: 3px solid hsla(259, 77%, 64%, 0.4);
		// }
	}
}
