@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TaskUpdateForm {
	width: 100%;
	height: 100%;

	&_resident {
		width: 100%;
		height: auto;
	}

	&_top {
		margin-top: 2rem;
		width: 100%;
		height: auto;
		border-radius: 0.5rem;
		@include flex_row(space-between, center);

		&_title {
			width: max-content;
			max-width: 100%;

			&_adl {
				font-size: 1.4rem;
				font-weight: 700;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-left: 2.8rem;
			}
			&_name {
				width: 100%;
				max-width: 100%;
				@include flex_col(flex-start, flex-end);
				position: relative;

				&_icon {
					position: absolute;
					top: 0.2rem;
					left: 0.8rem;

					&_statusBadge {
						width: 1.8rem;
						height: 1.8rem;
						fill: map-get($map: $blueGreys, $key: "subheadings");
						&:hover {
							cursor: pointer;
							fill: map-get($map: $colors, $key: "mainGreen");
						}
						&_isCompleted {
							@extend .TaskUpdateForm_top_title_name_icon_statusBadge;
							fill: map-get($map: $colors, $key: "mainGreen");
						}
					}
				}
				&_task {
					font-size: 1.8rem;
					font-weight: 800;
					color: map-get($map: $colors, $key: "mainBlackBlue");
					margin-left: 2.8rem;
					overflow-wrap: break-word;
					word-wrap: break-word;
					hyphens: auto;
				}
			}
		}

		&_date {
			width: auto;
			@include flex_row(flex-start, flex-end);

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $blueGreys, $key: "subheadings");
				margin-right: 0.5rem;
				// margin-left: auto;
			}
			&_text {
				font-size: 1.5rem;
				font-weight: 600;
				font-family: "Open Sans", "Arial", sans-serif;
				color: map-get($map: $blueGreys, $key: "subheadings");
				align-self: flex-start;
			}
		}
	}

	&_main {
		width: 100%;
		height: auto;
		padding: 0 2rem;

		&_isLocked {
			@include flex_row(flex-start, flex-start);
			margin-bottom: 2rem;

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $colors, $key: "mainRed");
			}
			& > span {
				color: map-get($map: $colors, $key: "mainRed");
				font-size: 1.4rem;
				font-weight: 600;
			}
		}

		&_desc {
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
			margin-bottom: 1rem;
		}

		&_editRecurring {
			position: absolute;
			top: 2rem;
			right: 0;
			border: none;
			outline: none;
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");
			font-size: 1.5rem;
			font-weight: 600;
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}

		// PROLLY NEED TO BE REMOVED???
		&_update {
			width: max-content;
			height: max-content;
			margin-bottom: 2rem;

			&_btn {
				@include flex_row(center, center);
				font-size: 1.5rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "main");
				border: none;
				outline: none;
				background-color: transparent;

				&_icon {
					width: 1.8rem;
					height: 1.8rem;
					margin-right: 0.5rem;
				}
			}
		}

		&_markComplete {
			width: max-content;
			height: max-content;
			margin-bottom: 2rem;

			&_btn {
				padding: 1rem 1.5rem;
				border-radius: 5rem;
				border: none;
				outline: none;
				background-color: map-get($map: $flatColors, $key: "mainGreen");
				color: map-get($map: $colors, $key: "main");
				@include flex_row(center, center);
				transition: 0.1s ease-in-out;

				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "main");
					color: #ffffff;
					cursor: pointer;
				}
				&:hover &_icon {
					cursor: pointer;
					fill: #ffffff;
				}

				&_icon {
					width: 1.7rem;
					height: 1.7rem;
					fill: map-get($map: $colors, $key: "main");
					margin-right: 0.3rem;
					transition: 0.1s ease-in-out;
					&_isComplete {
						cursor: pointer;
						@extend .TaskUpdateForm_main_markComplete_btn_icon;
						fill: #ffffff;
					}
				}
				&_isComplete {
					@extend .TaskUpdateForm_main_markComplete_btn;
					background-color: map-get($map: $colors, $key: "main");
					color: #ffffff;
					cursor: pointer;
				}
			}
		}

		&_subLabel {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "subheadings");
			margin-bottom: 1rem;
		}

		&_bottom {
			width: 100%;
			height: auto;
			margin: 4rem 0;
			@include flex_row(flex-end, center);

			&_icon {
				width: 1.4rem;
				height: 1.4rem;
				fill: #ffffff;
				margin-right: 0.3rem;
			}
			&_cancel {
				justify-self: center;
				width: 1.8rem;
				height: 1.8rem;
				fill: #ffffff;
				margin-right: 0.3rem;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}

		&_delete {
			width: 100%;
			height: auto;

			&_msg {
				font-size: 1.3rem;
				font-weight: 700;
				color: map-get($map: $colors, $key: "mainRed");
				margin-bottom: 2rem;
			}
			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: #ffffff;
			}
		}
	}
}

@media screen and (max-width: 650px) {
	.TaskUpdateForm {
		width: 100%;
		height: auto;

		&_top {
			@include flex_col(flex-start, flex-start);

			&_title {
				margin-bottom: 0.5rem;
				font-size: 1.5rem;
				white-space: pre-wrap;
			}
		}
	}
}
