@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentCard {
	display: block;
	margin: 2rem auto;
	max-width: 100%;
	width: 35rem;
	height: auto;
	padding: 1rem;
	border-radius: 0.5rem;
	-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	background-color: #ffffff;

	&_row {
		width: 100%;
		height: auto;
		@include flex_row(space-between, flex-start);
		margin-bottom: 2rem;

		&_top {
			width: 80%;
			@include flex_row(flex-start, center);

			&_group {
				width: auto;
				@include flex_col(flex-start, flex-start);
				margin-left: 1rem;

				&_title {
					color: map-get($map: $colors, $key: "mainBlackBlue");
				}

				&_subtitle {
					color: map-get($map: $blueGreys, $key: "headings");
				}
			}
		}

		&_menu {
			width: auto;
			height: auto;
			position: relative;

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");

				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}

			&_dropdown {
				position: absolute;
				right: 0;
				width: 12rem;
				height: 5rem;
				padding: 1rem;
				border-radius: 0.5rem;
				background-color: #ffffff;
				-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
				box-shadow: map-get($map: $shadows, $key: "heavy");

				&_option,
				&_option > a {
					color: map-get($map: $colors, $key: "main");
					text-decoration: none;
					text-align: center;

					&:hover {
						cursor: pointer;
						color: map-get($map: $colors, $key: "mainBlue");
					}
				}
			}
		}

		&_tile {
			width: 50%;
			height: auto;

			&_group {
				@include flex_row(center, center);
				margin-bottom: 1rem;

				&_subtitle {
					font-size: 1.3rem;
					font-weight: 600;
					text-align: center;
					padding-left: 3rem;
				}

				&_title {
					font-size: 1.6rem;
					margin-left: 1rem;
				}
			}

			&_icon {
				width: 2rem;
				height: 2rem;

				&_red {
					@extend .ResidentCard_row_tile_icon;
					fill: map-get($map: $colors, $key: "mainRed");
				}

				&_blue {
					@extend .ResidentCard_row_tile_icon;
					fill: map-get($map: $colors, $key: "main");
				}
			}

			&_plans {
				width: 100%;
				height: auto;
				font-size: 1.3rem;
				font-weight: 600;
				text-align: center;
				padding-left: 3rem;
			}
		}
	}

	&_details {
		width: 100%;
		height: auto;
		@include flex_row(center, flex-start);
		margin-bottom: 2rem;

		& > a {
			color: map-get($map: $colors, $key: "main");
			font-size: 1.1rem;
		}
	}

	&_viewDetails {
		display: block;
		margin: 0 auto;
		padding: 1.2rem 2.2rem;
		border-radius: 5rem;
		border: none;
		outline: none;
		background-color: map-get($map: $colors, $key: "main");
		color: #ffffff;
		font-size: 1.2rem;
		font-weight: 700;

		&:hover {
			cursor: pointer;
			-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}
	}
}

.isLOA {
	width: max-content;
	height: max-content;
	border-radius: 50%;

	&_active {
		@extend .isLOA;
		border: 5px solid map-get($map: $colors, $key: "mainRed");
	}
}

@media screen and (max-width: 400px) {
	.ResidentCard {
		display: block;
		margin: 2rem auto;
		max-width: 90%;
		width: 35rem;
		height: auto;
		padding: 1rem;
		border-radius: 0.5rem;
		-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
		box-shadow: map-get($map: $shadows, $key: "medSubtle");
		background-color: #ffffff;
		&_row_tile_plans {
			font-size: 1.1rem;
		}
		&_row_tile_group {
			font-size: 1.3rem;

			&_title {
				font-size: 1.3rem;
			}
		}
	}
}
