@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AppIcon {
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	@include flex_row(center, center);
	margin-right: 0.5rem;

	&_img {
		border-radius: 50%;
		width: 5rem;
		height: 5rem;
		// filter: drop-shadow(0.5rem 0.5rem 0.55rem rgba(0, 0, 0, 0.3));
		filter: drop-shadow(0.5rem 0.5rem 0.55rem hsla(210, 52%, 47%, 0.4));
	}
}
