@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UpdateButton {
	width: max-content;
	height: max-content;
	padding: 0.8rem 1.4rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "mainRed");
	color: #ffffff;
	border: none;
	outline: none;
	font-size: 1.3rem;
	font-weight: 600;
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	&:hover {
		cursor: pointer;
	}
}
