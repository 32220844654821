@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.OriginalTaskNote {
	width: 100%;
	height: auto;
	margin-bottom: 4rem;

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
	}

	&_inner {
		width: 100%;
		height: auto;
		// padding: 1rem 4rem;
		padding-top: 0.2rem;
		padding-bottom: 1rem;
		padding-left: 4rem;
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");

		&_badge {
			font-size: 1.4rem;
			font-weight: 600;
			font-style: italic;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
}
.OriginalTaskNoteContent {
	width: 100%;
	height: auto;
	font-size: 1.5rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "800");
}

// TOP SECTION
.NotesUserBadge {
	position: relative;
	min-width: 3rem;
	width: 3rem;
	min-height: 3rem;
	height: 3rem;
	@include flex_row(center, center);
	border-radius: 50%;
	box-shadow: map-get($map: $shadows, $key: "subtle");
	background-color: map-get($map: $pinkMap, $key: "500");

	&_initials {
		@include fill_container;
		@include flex_row(center, center);
		background-color: transparent;
		font-size: 1.4rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "50");
	}
}
.NotesUserName {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	// margin-left: 0.5rem;
	margin: 0 1rem;

	&_user {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}
.NotesTimeStamp {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	font-size: 1.4rem;
	font-weight: 500;
	font-family: map-get($map: $fonts, $key: "altSans");
	color: map-get($map: $blueGreyMap, $key: "500");
	margin-right: 1rem;
}
