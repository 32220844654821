@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlShiftResidentRow {
	width: 100%;
	height: 6rem;
	@include flex_row(space-around, center);

	&_cell {
		width: 15rem;
		height: 100%;
		border-left: 1px solid #ccc;
		border-bottom: 1px solid #ccc;

		&_name {
			width: 15rem;
			@include fill_container;
			@include flex_col(center, center);

			&_resident {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}
			&_id {
				font-size: 1.2rem;
				font-weight: 400;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
	}

	&_shiftCell {
		height: 100%;
		flex-grow: 1;

		&:nth-child(odd) {
			border-bottom: 1px solid #ccc;
		}
		&:nth-child(even) {
			border: 1px solid #ccc;
			border-top: none;
		}
		&:last-child {
			border-right: none;
		}

		&_shift {
			@include fill_container;
			padding: 1rem;
			@include flex_row(center, center);

			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $colors, $key: "main");
			}
		}
	}

	&:nth-child(even) {
		border-bottom: 1px solid #eaecef;
	}
}
