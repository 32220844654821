@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ALASelector {
	width: 100%;
	height: auto;
	max-height: 6rem;
	@include flex_row(center, center);
}
