@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailySummaryCardBottom {
	position: relative;
	width: 100%;
	// min-height: auto; // makes bottom height dynamic instead of static
	min-height: 5.8rem; // makes bottom height static instead of dynamic
	height: 100%;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	@include flex_row(space-between, flex-end);
	z-index: 20;
}
