@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportViewer {
	width: 100%;
	height: auto;
	max-height: 100rem;

	&_placeholder {
		width: 100%;
		height: 30%;
		@include flex_row(center, center);
	}
}
