@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Message {
	width: 100%;
	height: 50vh;
	@include flex_col(center, center);

	&_text {
		font-size: 1.8rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "headings");
	}
}
