@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CalendarView {
	@include fill_container;
	padding: 4rem 2rem 2rem 2rem;

	&_shifts {
		width: 100%;
	}

	&_header {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
		margin-bottom: 4rem;
	}

	&_panel {
		width: 100%;
		height: 6rem;
		padding: 1rem;
		background-color: #ffffff;
		border-radius: 0.5rem;
		margin-bottom: 2rem;
		box-shadow: map-get($map: $shadows, $key: "medSubtle");

		&_heading {
			width: 100%;
			height: auto;
			@include flex_row(flex-start, center);

			&_title {
				font-size: 1.8rem;
				& > b {
					color: map-get($map: $colors, $key: "main");
				}
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.CalendarView {
		padding: 4rem 1rem 2rem 1rem;
	}
}
