@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.NewTaskNote {
	width: 100%;
	height: auto;

	&_input {
		width: 100%;
		height: auto;
	}
	&_actions {
		width: 100%;
		height: auto;
		// padding-bottom: 1rem;
		@include flex_row(flex-start, center);
		// box-shadow BOTTOM ONLY
		// box-shadow: map-get($map: $shadows, $key: "bottom");
	}
}

.SavedMessage {
	width: 100%;
	height: auto;
	@include flex_row(flex-start, center);
	margin-bottom: 2rem;

	&_iconWrapper {
		width: auto;
		height: auto;
		margin-right: 0.5rem;

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $greenMap, $key: "500");
		}
	}
	&_msg {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $greenMap, $key: "600");
	}
}
