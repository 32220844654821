@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ForgotPasswordButton {
	display: block;
	margin: 2rem auto;
	width: 35rem;
	padding: 0 2rem;
	background-color: transparent;
	@include flex_row(center, center);
	margin-top: 4rem;

	&_btn {
		padding: 0.5rem 1.3rem;
		border-radius: 0.5rem;
		border: none;
		outline: none;
		color: map-get($map: $colors, $key: "main");
		font-weight: 600;
		font-size: 1.3rem;
		&:hover {
			cursor: pointer;
			color: map-get($map: $blueMap, $key: "500");
		}
	}
}
