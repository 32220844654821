@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AlertsDashboard {
	@include fill_container;
	padding: 2rem 4rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_title {
			font-size: 2.8rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainRed");
			padding-left: 4rem;
		}
	}

	&_main {
		@include fill_container;
		@include flex_row(space-between, flex-start);
	}

	&_section {
		@include fill_container;
		padding: 2rem;

		&_sectionTitle {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}
}

@media only screen and (max-width: 500px) {
	.AlertsDashboard {
		padding: 2rem 0.5rem;

		&_viewTitle {
			font-size: 2.4rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainRed");
		}

		&_section {
			@include fill_container;
			padding: 0.5rem;
		}
	}
}
