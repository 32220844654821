@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportDateHandler {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin-top: 1rem;
}
