@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ServicePlanPickerController {
	@include fill_container;

	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
		margin-bottom: 4rem;

		&_cancelBtn {
			padding: 1.2rem 2rem;
			border-radius: 5rem;
			border: 1px solid #ccc;
			outline: none;
			background-color: transparent;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
		&_createBtn {
			padding: 1.2rem 2rem;
			border-radius: 5rem;
			border: none;
			outline: none;
			background-color: map-get($map: $colors, $key: "main");
			color: #ffffff;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}

	&_divider {
		opacity: 0.4;
		margin-bottom: 1rem;
	}
}
