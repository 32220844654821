@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TimeViewResidentPhoto {
	position: relative;
	padding: 1rem 1rem;
	width: 100%;
	height: auto;
	@include flex_col(flex-start, center);
}
