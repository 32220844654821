@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateADLTaskButton {
	padding: 0.6rem 1.2rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $purpleMap, $key: "50");
	@include flex_row(center, center);
	margin-left: auto;
	border: none;
	outline: none;
	cursor: pointer;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}

	&_icon {
		width: 1.7rem;
		height: 1.7rem;
		fill: #ffffff;
		fill: map-get($map: $purpleMap, $key: "600");
	}
	&_text {
		font-size: 1.2rem;
		font-weight: 600;
		color: #ffffff;
		color: map-get($map: $purpleMap, $key: "600");
		margin-left: 0.4rem;
	}
}
