@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlTableHeading {
	width: 100%;
	min-height: 6rem;
	padding: 1rem;
	border-bottom: 1px solid #eaecef;

	&_top {
		@include fill_container;
		height: 6rem;
		padding: 0.5rem;
		// background-color: #eee;
		font-size: 1.6rem;
		font-weight: 700;
		border-radius: 0.5rem;
		color: map-get($map: $colors, $key: "mainBlackBlue");
		@include flex_row(space-between, flex-start);

		&_options {
			width: max-content;
			height: max-content;

			&_btn {
				border: none;
				outline: none;
				background-color: transparent;
				color: map-get($map: $colors, $key: "main");
				font-size: 1.3rem;
				font-weight: 600;
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainBlue");
				}
			}
		}
	}
	&_settingsPanel {
		width: 100%;
		height: auto;
		min-height: 6rem;
		padding: 1rem 2rem;

		&_edits {
			width: 100%;
			height: auto;

			&_count {
				font-size: 1.5rem;
				font-weight: 500;
				font-family: map-get($map: $fonts, $key: "altSans");

				& > b {
					font-weight: 600;
					&:first-of-type {
						color: map-get($map: $colors, $key: "main");
					}
					&:last-of-type {
						color: map-get($map: $colors, $key: "main");
					}
				}
			}
		}
		&_actions {
			margin: 2rem 0;
			@include flex_row(flex-start, center);
		}
	}
}
