@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportSortOptions {
	width: 100%;
	height: auto;
	padding: 1rem 2rem;

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-bottom: 1rem;
	}
}
