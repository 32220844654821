@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RepeatDesc {
	width: 100%;
	height: auto;

	&_noRepeat {
		width: 100%;

		&_msg {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainRed");
		}
		&_addRepeat {
			margin-top: 1rem;
			padding: 0.5rem 1rem;
			border: none;
			outline: none;
			background-color: transparent;
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "main");
			border: 2px solid map-get($map: $colors, $key: "main");
			border-radius: 0.5rem;
			transition: all 0.3s ease-in-out;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&_section {
		width: 100%;
		@include flex_col(flex-start, flex-start);
		font-family: map-get($map: $fonts, $key: "altSans");
		margin-bottom: 1rem;

		&_title {
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_desc {
			padding-left: 1rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_days,
		&_shifts {
			@include flex_row(flex-start, center);
		}
		&_dates {
			padding-left: 1rem;
			@include flex_col(flex-start, flex-start);

			& > span {
				font-size: 1.3rem;
				font-weight: 500;
			}
		}
	}
}

.DayIndicator {
	padding: 0.3rem 0.7rem;
	border-radius: 5rem;
	border: 1px solid map-get($map: $colors, $key: "mainBlackBlue");
	@include flex_row(center, center);
	background-color: transparent;
	opacity: 0.2;
	margin: 0 0.3rem;
	outline: none;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	&_isActive {
		@extend .DayIndicator;
		color: #ffffff;
		background-color: map-get($map: $colors, $key: "main");
		opacity: 1;
	}

	&_day {
		font-size: 1.2rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainBlackBlue");

		&_isActive {
			@extend .DayIndicator_day;
			color: #ffffff;
		}
	}
}

.ShiftIndicator {
	padding: 0.3rem 0.7rem;
	border-radius: 5rem;
	border: 1px solid map-get($map: $colors, $key: "mainBlackBlue");
	@include flex_row(center, center);
	background-color: transparent;
	opacity: 0.2;
	margin: 0 0.3rem;
	outline: none;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	&_isActive {
		@extend .ShiftIndicator;
		color: #ffffff;
		background-color: map-get($map: $colors, $key: "main");
		opacity: 1;
	}

	&_day {
		font-size: 1.2rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainBlackBlue");

		&_isActive {
			@extend .ShiftIndicator_day;
			color: #ffffff;
		}
	}
}
