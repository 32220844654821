@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TableBody {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
}
