@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UpdateBanner {
	width: 100vw;
	height: 10rem;
	position: fixed;
	top: 0;
	left: 0;
	background-color: map-get($map: $colors, $key: "mainBlue");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	z-index: 1000;

	&_inner {
		@include fill_container;
		@include flex_row(center, center);
		padding: 1rem 1.5rem;

		&_content {
			width: auto;

			&_msg {
				font-size: 2rem;
				font-weight: 800;
				font-family: "Open Sans", "Roboto", "Arial", sans-serif;
				color: #ffffff;
				margin-right: 1rem;
			}
		}

		&_button {
			@include flex_row(center, center);
			width: auto;
			height: 100%;
		}
	}
}
