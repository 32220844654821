@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentHeader {
	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	@include flex_row(flex-start, center);
	margin-bottom: 6rem;

	&_photo {
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		margin-right: 0.5rem;
	}
	&_desc {
		width: auto;
		@include flex_col(flex-start, flex-start);

		&_name {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");

			&_empty {
				font-size: 1.6rem;
				font-weight: 700;
				color: map-get($map: $colors, $key: "mainRed");
			}
			&_id {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
			}
		}
		&_residentID {
			font-size: 1.3rem;
			font-weight: 400;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
	}
}
