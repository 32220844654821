@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

// custom title wrapper for ModalSM HelpLegend
.HelpTitle {
	@include flex_row(flex-start, center);
	& > span {
		font-size: 2rem;
	}
}

.HelpIcon {
	width: 2rem;
	height: 2rem;
	padding: 0.2rem;
	border-radius: 50%;
	background-color: hsla(210, 52%, 47%, 1);
	fill: #ffffff;
	margin-left: 0.5rem;
}

.HelpLegend {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background-color: hsla(210, 52%, 47%, 1); // hsla(210, 52%, 47%, 1)
	z-index: 20;

	&:hover {
		cursor: pointer;
		background-color: hsla(210, 52%, 47%, 0.6);
	}
	&:hover &_floating {
		background-color: hsla(210, 52%, 47%, 0.6);
	}
	&:hover &_floating_icon {
		background-color: hsla(210, 52%, 47%, 0.6);
	}

	&_floating {
		@include fill_container;
		@include flex_row(center, center);
		border-radius: 50%;
		background-color: hsla(210, 52%, 47%, 1); // hsla(210, 52%, 47%, 1)

		&_icon {
			background-color: hsla(210, 52%, 47%, 1);
			fill: #ffffff;
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	// MODAL
	&_section {
		width: 100%;
		height: auto;

		&_title {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_entry {
			width: 100%;
			height: auto;
			padding: 1.5rem 0.5rem;
			margin-bottom: 1rem;
			color: map-get($map: $blueGreys, $key: "headings");

			&_badges {
				width: -webkit-max-content;
				width: -moz-max-content;
				width: max-content;
				height: -webkit-max-content;
				height: -moz-max-content;
				height: max-content;
				margin-bottom: 1rem;
			}

			&_text {
				font-size: 1.4rem;
				font-weight: 500;
				margin-bottom: 0.5rem;
			}

			&_numberedList {
				font-size: 1.4rem;
				list-style-type: decimal;
				&_item {
					font-size: 1.2rem;
					font-weight: 300;
					color: map-get($map: $colors, $key: "mainBlackBlue");
					margin-top: 1rem;
				}
			}
		}
	}
}

@media screen and (max-width: 645px) {
	.HelpLegend {
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		background-color: hsla(210, 52%, 47%, 1); // hsla(210, 52%, 47%, 1)
		z-index: 20;
	}
	.HelpTitle {
		@include flex_row(flex-start, center);
		& > span {
			font-size: 1.6rem;
		}
	}
	.HelpIcon {
		width: 1.6rem;
		height: 1.6rem;
	}
}
