@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentServicePlan {
	width: 120rem;
	max-width: 100%;
	min-width: 100%;
	height: auto;
	min-height: 50rem;
	border-radius: 0.5rem;
	border: 2px solid #ccc;
	margin-top: 4rem;
}
