@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailySummaryCardToolTip {
	position: absolute;
	top: -2.35rem;
	left: 37%;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	min-width: auto;
	padding: 0.3rem 0.5rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	opacity: 0.7;
	z-index: 0;

	&_residentName {
		font-size: 1.1rem;
		font-weight: 600;
		color: #ffffff;
		@include flex_row(center, center);
		text-align: center;
	}
}
