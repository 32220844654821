@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdlRow {
	width: 100%;
	max-width: 100%;
	height: 5rem;
	@include flex_row(center, center);
	flex-grow: 1;
	flex-shrink: 1;

	&:nth-child(even) {
		border-top: 1px solid #eaecef;
		border-bottom: 1px solid #eaecef;
	}
	&:last-of-type {
		border-bottom: 1px solid #eaecef;
	}
}
