@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentPanel {
	width: 100%;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	margin-top: 3rem;
	box-shadow: map-get($map: $shadows, $key: "subtle");

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 1rem;
		@include flex_row(flex-start, center);
		border-bottom: 1px solid #eaecef;

		&_title {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	&_content {
		@include fill_container;
		padding: 1rem;
	}
	&_archived {
		color: map-get($map: $colors, $key: "mainRed");
		font-size: 1.2rem;
		font-weight: 600;
		font-style: italic;
		margin-left: 1rem;
		padding: 0.5rem;
		border-radius: 5rem;
		background-color: map-get($map: $flatColors, $key: "mainRed");
		border: 1px solid map-get($map: $colors, $key: "mainRed");
	}
}
