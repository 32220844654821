@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DailyADL {
	width: calc(100% / 8);
	max-height: 100%;
	padding: 0.5rem 0.3rem;
	@include flex_col(center, center);
	flex-shrink: 1;
	flex-grow: 1;

	&_shifts {
		width: 100%;
		height: 33.33%;
		-webkit-box-flex: 1;
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0.25rem 0 0.3rem 0;
	}
}

.TodaysDailyADL {
	width: calc(100% / 8);
	max-height: 100%;
	padding: 0.5rem 0.3rem;
	@include flex_col(center, center);
	flex-shrink: 1;
	flex-grow: 1;
	border-radius: 0.5rem 0.5rem;
	background-color: map-get($map: $greys, $key: "extraLightGrey");
	box-shadow: map-get($map: $shadows, $key: "mainOutline");

	&_shifts {
		width: 100%;
		height: 33.33%;
		-webkit-box-flex: 1;
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0.3rem 0 0.3rem 0;
	}
}
