@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportsDashboard {
	@include fill_container;

	&_heading {
		width: 100%;
		padding: 2rem 4rem;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "main");
			text-align: center;
		}
		&_subtitle {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	&_reports {
		@include flex_row(space-evenly, center, wrap);
		align-content: space-around;

		&_cardIcon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $colors, $key: "mainBlackBlue");
		}
		& p {
			font-size: 1.2rem;
			font-family: map-get($map: $fonts, $key: "mainSans");
			text-align: left;
			color: map-get($map: $blueGreys, $key: "headings");
			opacity: 0.8;
		}
	}
}
