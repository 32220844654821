@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentTable {
	position: relative;
	width: 100%;
	height: 100rem;
	background-color: #ffffff;
	border-radius: 0.5rem;
	padding: 0.5rem;
	padding-bottom: 2rem;
	margin-bottom: 2rem;
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	z-index: 5;

	&_top {
		width: 100%;
		height: 5rem;
		padding: 0.5rem 1rem;
		border-bottom: 1px solid #eaecef;
		@include flex_row(flex-start, flex-start);
		margin-bottom: 2rem;

		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $blueGreys, $key: "headings");
		}
		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			margin-left: 0.5rem;
		}

		&_filterIcon {
			margin-left: auto;
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreys, $key: "headings");
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "main");
			}
		}
	}
	// TABLE FILTERS - TOGGLEABLE SECTION
	&_filters {
		width: 100%;
		height: auto;
		min-height: 6rem;
		padding-top: 0;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 2rem;

		&_search {
			width: 100%;
			@include flex_row(flex-end, center);
		}

		&_controls {
			width: 100%;
			height: max-content;
			@include flex_row(flex-start, center);
		}
	}

	// TABLE BODY - DATA
	&_body {
		@include fill_container;
		min-height: 40rem;
		max-height: 80rem;
		overflow-y: auto;
	}
}

.SelectedCount {
	margin-bottom: 1rem;
	font-size: 1.5rem;
	font-weight: 600;
}
