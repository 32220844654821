@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";
@import "../../sass/animations";

.DailySummaryListItem {
	position: relative;
	width: 100%;
	padding: 0.8rem 1.7rem;
	padding-left: 2rem;
	border: 1px solid map-get($map: $colors, $key: "mainGrey");
	border-radius: 0.5rem;
	background-color: #ffffff;
	color: map-get($map: $colors, $key: "mainBlackBlue");
	// CREATES BLURRED SHADOW AROUND TASK ENTRIES
	// -webkit-box-shadow: map-get($map: $shadows, $key: "subtleInset");
	// box-shadow: map-get($map: $shadows, $key: "subtleInset");
	margin-bottom: 0.5rem;
	z-index: 1;

	&_taskNumber {
		position: absolute;
		top: 1rem;
		left: 0.7rem;
		font-size: 1rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_shift {
		position: absolute;
		top: 2.5rem;
		left: 0.3rem;
		font-size: 1rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}

	&:hover {
		cursor: pointer;
		background-color: #ffffff;
		box-shadow: map-get($map: $shadows, $key: "subtle");
	}

	&:hover &_item_icon,
	&:hover &_item_count {
		fill: map-get($map: $colors, $key: "main");
		color: map-get($map: $colors, $key: "main");
	}

	&_details {
		@include fill_container;
		@include flex_row(space-between, center);
		padding-left: 0.8rem;
		margin-bottom: 1rem;

		&_desc {
			font-size: 1.1rem;
		}

		&_exception {
			margin-left: auto;
			width: max-content;
			height: max-content;
			@include flex_row(flex-end, center);

			&_desc {
				font-size: 1.2rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainRed");
				margin-right: 0.3rem;
			}

			&_icon {
				width: 1.8rem;
				height: 1.8rem;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
	}

	&_item {
		height: max-content;
		@include flex_row(flex-start, center);
		padding-left: 0.8rem;

		&:hover > &_count {
			cursor: pointer;
			color: map-get($map: $colors, $key: "main");
			fill: map-get($map: $colors, $key: "main");
		}

		&_markComplete {
			@include flex_row(flex-start, center);

			&_icon {
				width: 1.2rem;
				height: 1.2rem;
				fill: map-get($map: $blueGreys, $key: "headings");
				&:hover {
					cursor: pointer;
				}
			}
			&_text {
				font-size: 1.2rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainGreen");
				cursor: pointer;
			}
		}

		&_options {
			width: max-content;
			margin-left: auto;

			& > div {
				margin-right: 1rem;
			}
		}

		&_advanced {
			border: none;
			background-color: transparent;
			color: map-get($map: $colors, $key: "main");
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}

		&_status {
			width: 1.2rem;
			height: 1.2rem;
			border-radius: 50%;
			background-color: map-get($map: $colors, $key: "mainGreen");
			align-self: center;

			&_isCompleted {
				@extend .DailySummaryListItem_item_status;
				background-color: map-get($map: $flatColors, $key: "mainGreen");
				border: 1px solid map-get($map: $colors, $key: "mainGreen");
			}
			&_isPastDue {
				@extend .DailySummaryListItem_item_status;
				background-color: map-get($map: $flatColors, $key: "mainRed");
				border: 1px solid map-get($map: $colors, $key: "mainRed");
			}
			&_isNotCompleted,
			&_isPending {
				@extend .DailySummaryListItem_item_status;
				background-color: map-get($map: $colors, $key: "altYellow");
				border: 1px solid map-get($map: $colors, $key: "mainBlackBlue");
			}

			&_badge {
				width: 1.2rem;
				height: 1.2rem;
			}
		}

		&_shift {
			font-size: 1rem;
			font-weight: 600;
			margin-left: 0.5rem;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}

		&_repeat {
			width: max-content;
			height: max-content;
			margin-left: 1rem;
			@include flex_row(center, flex-start);

			&_icon {
				width: 1.4rem;
				height: 1.4rem;
				fill: map-get($map: $colors, $key: "main");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainBlue");
				}
			}
		}

		&_createdTask {
			width: max-content;
			height: max-content;

			&_icon {
				width: 1.6rem;
				height: 1.6rem;
				fill: map-get($map: $colors, $key: "main");
				margin-left: 0.3rem;
			}
		}

		&_icon {
			width: 1.4rem;
			height: 1.4rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");
			margin-right: 0.3rem;

			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "main");
			}
		}

		&_count {
			color: map-get($map: $blueGreys, $key: "headings");
			font-weight: 700;
			margin-right: 0.3rem;
		}
		&_clickComplete {
			margin-left: auto;
			margin-right: 0.5rem;
			@include flex_row(flex-start, center);
			border: none;
			outline: none;
			background-color: transparent;
			font-size: 1.2rem;
			color: map-get($map: $colors, $key: "mainGreen");
			&:hover {
				cursor: pointer;
			}

			&_icon {
				width: 1.4rem;
				height: 1.4rem;
				fill: map-get($map: $colors, $key: "mainGreen");
				margin-right: 0.2rem;
			}
		}
	}
	&_isCompleted {
		@extend .DailySummaryListItem;
		opacity: 0.4;
		border-left: 4px solid map-get($map: $colors, $key: "mainGreen");
		z-index: 1;
		&:hover {
			opacity: 0.8;
			border-left: 4px solid map-get($map: $flatColors, $key: "mainGreen");
		}
	}
	&_isMissedEvent {
		@extend .DailySummaryListItem;
		border-left: 4px solid map-get($map: $flatColors, $key: "mainRed");
		transition: all 0.3 ease-in-out;
		z-index: 1;
		&:hover {
			border-left: 4px solid map-get($map: $colors, $key: "mainRed");
		}
	}
	&_isPastDue {
		@extend .DailySummaryListItem;
		border-left: 4px solid map-get($map: $colors, $key: "main");
		z-index: 1;
		&:hover {
			cursor: pointer;
			border-left: 4px solid map-get($map: $colors, $key: "main");
		}
	}
	&_isNotCompleted,
	&_isPending {
		@extend .DailySummaryListItem;
		cursor: pointer;
		border-left: 4px solid map-get($map: $colors, $key: "altYellow");
		z-index: 1;
	}
}

.wasUpdated {
	@include beacon;
}
