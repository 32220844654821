@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReportShiftOption {
	@include flex_row(flex-start, center);
	margin-bottom: 1rem;

	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
		margin-left: 1rem;
	}
}

.ReportShiftPicker {
	width: 100%;
}
