@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ShiftDetailsTask {
	position: relative;
	width: 100%;
	height: 10rem;
	padding: 1rem 3.5rem;
	border: 1px solid #ccc;
	border-radius: 0.5rem;
	margin-bottom: 1rem;
	@include flex_col(space-between, flex-start);

	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "medSubtle");
	}
	&:hover &_name_label {
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&_taskIndex {
		position: absolute;
		top: 0.3rem;
		left: 0.2rem;
		font-size: 2.5rem;
	}

	&_name {
		width: max-content;
		height: max-content;
		@include flex_col(flex-start, flex-start);

		&_label {
			font-size: 1.2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
		}
		&_desc {
			font-size: 1rem;
			font-weight: 400;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}

	&_shift {
		width: max-content;
		height: max-content;
		margin-bottom: 0.5rem;

		&_text {
			font-size: 1.2rem;
			font-weight: 600;
		}
	}

	&_bottom {
		width: 100%;
		height: max-content;
		@include flex_row(space-between, center);

		&_status {
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			box-shadow: map-get($map: $shadows, $key: "medSubtle");
		}
		&_recurring {
			align-self: flex-end;
			width: max-content;
			height: max-content;

			&_icon {
				width: 1.7rem;
				height: 1.7rem;
				fill: map-get($map: $colors, $key: "main");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "mainBlue");
				}
			}
		}
	}

	&_isSelected {
		@extend .ShiftDetailsTask;
		border: 1px solid map-get($map: $colors, $key: "main");
		box-shadow: map-get($map: $shadows, $key: "medSubtle");

		&:hover {
			cursor: not-allowed;
		}
	}
}
