@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MonthPickerMonth {
	width: 100%;
	height: 100%;
	padding: 0.5rem 1rem;
	@include flex_row(center, center);
	border: 1px solid #ccc;
	border-radius: 0.5rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}

	&_month {
		font-size: 1.4rem;
	}

	&_isSelected {
		@extend .MonthPickerMonth;
		background-color: map-get($map: $flatColors, $key: "main");
		color: #ffffff;
	}
	&_isRestricted {
		@extend .MonthPickerMonth;
		opacity: 0.2;
		cursor: not-allowed;
		&:hover {
			cursor: not-allowed;
		}
	}
}
