@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentDetails {
	width: 100%;
	height: 11rem;
	padding: 0.5rem;
	position: relative;
	@include flex_row(flex-start, flex-start);
	flex-basis: 1;
	flex-shrink: 1;
	background-color: #ffffff;
	border-radius: 1rem 1rem 0 0;

	&_resInfo {
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		height: 100%;
		padding: 1rem 1rem 0.5rem 0.5rem;
		margin-left: 0.5rem;

		&_name {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			margin-bottom: 0.5rem;
		}
		&_detail {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "headings");
			margin-bottom: 0.3rem;
		}
	}

	&_legend {
		width: max-content;
		height: 100%;
		padding: 0.8rem 1rem;
		@include flex_row(flex-end, flex-start);
		margin-left: auto;

		&_wrapper {
			&_item {
				@include flex_row(flex-start, center);
				margin-bottom: 0.3rem;

				&_badge {
					width: 1.5rem;
					height: 1.5rem;
					border-radius: 50%;
					margin-right: 0.5rem;
					box-shadow: map-get($map: $shadows, $key: "light");
				}
				&_desc {
					font-size: 1.2rem;
					font-weight: 500;
				}
			}
			&_desc {
				margin-top: 0.5rem;
				width: 14rem;
				height: 100%;
				overflow-wrap: break-word;

				&_text {
					font-size: 1.1rem;
				}
			}
		}
	}
}
