@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PastDueEntry {
	position: relative;
	width: 100%;
	height: 10rem;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	display: grid;
	grid-template-rows: repeat(2, auto-fit);
	grid-template-columns: repeat(2, auto-fit);
	grid-auto-flow: row;
	background-color: #ffffff;
	border: 1px solid map-get($map: $colors, $key: "mainGrey");
	box-shadow: map-get($map: $shadows, $key: "subtle");
	margin-bottom: 1.5rem;

	&_isComplete {
		@extend .PastDueEntry;
		opacity: 0.5;
	}

	&_taskNumber {
		position: absolute;
		top: 0.3rem;
		left: 0.3rem;
		font-size: 1.2rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}

	&_group {
		width: 100%;
		height: 100%;
		padding: 0.3rem 0.5rem;

		&_two {
			@extend .PastDueEntry_group;
			grid-column-start: 2;
			@include flex_row(flex-end, flex-start);
		}
		&_three {
			@extend .PastDueEntry_group;
			@include flex_row(flex-start, flex-end);
			font-family: map-get($map: $fonts, $key: "altSans");

			&_date {
				color: map-get($map: $blueGreys, $key: "headings");
				font-size: 1.2rem;
				font-weight: 400;
				margin-left: 0.3rem;
				& > b {
					color: map-get($map: $colors, $key: "mainRed");
					font-size: 1.3rem;
				}
			}
		}

		&_four {
			position: relative;
			@extend .PastDueEntry_group;
			grid-column-start: 2;
			grid-row-start: 2;
			@include flex_row(flex-end, flex-end);
		}

		&_title {
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_text {
			font-size: 1.2rem;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}

	&_status {
		font-weight: 600;
		margin-left: auto;
		justify-self: flex-end;

		&_text {
			padding: 0.5rem 1rem;
			border: 1px solid map-get($map: $colors, $key: "mainRed");
			background-color: map-get($map: $flatColors, $key: "mainRed");
			border-radius: 5rem;
		}
	}

	&_icon {
		width: 1.4rem;
		height: 1.4rem;
		fill: map-get($map: $blueGreys, $key: "headings");
		margin-bottom: 0.165rem;
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "main");
		}
	}
}
#menu {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

// RECORD OPTIONS MENU
.PastDueEntryMenu {
	width: 15rem;
	height: 15rem;
	position: absolute;
	display: block;
	top: 0;
	right: 0;
	padding: 0.5rem 0;
	border-radius: 0.5rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "raised");
	z-index: 10;

	&_list {
		list-style: none;

		&_item {
			width: 100%;
			padding: 0.5rem 1rem;
			margin-bottom: 0.8rem;
			font-size: 1.2rem;

			&:hover {
				cursor: pointer;
				background-color: map-get($map: $greys, $key: "chalk");
			}
			&:nth-child(1) {
				@include flex_row(flex-end, flex-start);
				margin-bottom: 0;
				&:hover {
					background-color: transparent;
				}
			}
			&:nth-child(2) {
				color: map-get($map: $colors, $key: "mainGreen");
			}
			&:nth-child(4) {
				@include flex_row(flex-start, center);
				color: map-get($map: $colors, $key: "mainRed");
				& > svg {
					margin-right: 0.5rem;
					margin-left: -0.3rem;
				}
			}
		}
	}
}
