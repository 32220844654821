@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoginPage {
	@include fill_container;
	@include flex_col(flex-start, center);

	&_brandLogo {
		position: absolute;
		display: block;
		top: 1rem;
		left: 1rem;
		width: 5.5rem;
		height: 5.5rem;
		border-radius: 50%;
		padding-top: 0.3rem;

		&_img {
			width: 4rem;
			height: 4rem;
			object-fit: contain;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&_banner {
		width: 100%;
		height: auto;
		padding-top: 5rem;
		margin-bottom: 8rem;
		@include flex_row(center, flex-start);

		&_wrapper {
			@include flex_row(center, center);
			width: 37rem;
			min-width: fit-content;
			height: 10rem;
			border-radius: 5rem;
			// background-color: #ffffff;
			// box-shadow: 3px 3px 20px -4px hsla(210, 52%, 47%, 0.8);

			&_appLogo {
				width: auto;
				height: 10rem;
				margin-right: 2rem;
				margin-right: auto;
				margin-right: 0.5rem;
			}
			&_appName {
				font-size: 4rem;
				font-weight: 700;
				color: hsla(210, 52%, 47%, 1);
				margin-left: auto;
				margin-right: auto;
				margin-right: 3rem;
			}
		}
	}

	&_logo {
		@include flex_row(center, center);
		width: 100%;
		height: 15rem;
		background-color: transparent;
		margin-bottom: 4rem;
	}
	&_content {
		width: 100%;
		height: auto;
		@include flex_col(flex-start, center);
	}
	&_extra {
		width: 100%;
		height: 20rem;
		@include flex_row(center, flex-end);
	}
}

.Message {
	position: absolute;
	width: initial;
	height: initial;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	top: 1rem;
	right: 1rem;
	@include flex_row(flex-start, center);
	background-color: map-get($map: $flatColors, $key: "mainGreen");
	box-shadow: map-get($map: $shadows, $key: "heavySubtle");

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $colors, $key: "main");
		margin-right: 0.5rem;
	}
	&_msg {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "main");
	}
}

@media only screen and (max-width: 550px) {
	.LoginPage {
		@include fill_container;

		&_logo {
			width: 100%;
			@include flex_row(center, center);

			&_image {
				max-width: 40rem;
				width: 100%;
				height: auto;
			}
		}

		&_banner {
			width: 100%;
			margin-bottom: 5rem;

			&_wrapper {
				max-width: 30rem;
				height: 8rem;

				&_appLogo {
					width: 8rem;
					height: 8rem;
					margin-right: auto;
				}
				&_appName {
					font-size: 3rem;
					font-weight: 700;
					color: hsla(210, 52%, 47%, 1);
					text-align: center;
					justify-self: center;
				}
			}
		}
	}
}
@media only screen and (max-width: 430px) {
	.LoginPage {
		@include fill_container;

		&_logo {
			width: 100%;
			@include flex_row(center, center);
			margin-bottom: 2rem;

			&_image {
				max-width: 30rem;
				width: 100%;
				height: 8rem;
			}
		}
	}
}
@media only screen and (max-width: 360px) {
	.LoginPage {
		@include fill_container;

		&_logo {
			width: 100%;
			height: 10rem;
			@include flex_row(center, center);
			margin-bottom: 2rem;

			&_image {
				max-width: 30rem;
				width: 100%;
				height: 8rem;
			}
		}
		&_content {
			padding: 0 0.8rem;
		}
	}
}
